import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import cloud from '../../assets/images/servicios/cloud.svg';
import { Button, CardActions } from '@material-ui/core';
import { Link } from "react-router-dom";
import UseStyles from './UseStyless';

export default function CardsCloud() {
    const classes = UseStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <img alt='Cloud' className={classes.rooter} src={cloud}></img>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2" style={{ fontDisplay:"auto", textAlign: 'center', color: 'var(--rojo)' }}>
                        Cloud
                    </Typography>
                    <Typography variant="body2" component="p" style={{fontDisplay:"auto", textAlign: 'center', color: 'white' }}>
                        La agilidad, la experimentación y nivel de servicio como generador de valor para su negocio.
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ maxHeight: '40px', alignItems: 'flex-end', justifyContent: 'center' }}>
                <Link to="/servicios" style={{textDecoration: 'none'}}>
                    <Button className={classes.button} size="small" color="primary" style={{ marginTop: '28px', width: '100%' }}>
                        Ver Más
                    </Button>
                </Link>
            </CardActions>
        </Card>
    );
}