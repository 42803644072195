import React from 'react'
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

export default function Nosotros() {
    const classes = useStyles();
    const [open, setOpenNosotros] = React.useState(false);
    const anchorRef = React.useRef(null);

    function handleToggleNosotros () {
        setOpenNosotros((openvalueNosotros) => !openvalueNosotros);
    };

    function handleCloseNosotros (event) {
        if (anchorRef.current?.contains(event.target)) {
            return;
        }

        setOpenNosotros(false);
    };

    function handleListKeyDownNosotros(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenNosotros(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggleNosotros}
                style={{ color: 'white', minWidth: '100px', fontFamily: 'Raleway', textTransform: 'capitalize' }}
            >
                Nosotros
        </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseNosotros}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDownNosotros}>
                                    <Link to="/nosotros/cultura" style={{ textDecoration: "none", color: "black" }}>
                                        <MenuItem onClick={handleCloseNosotros}>
                                            Nuestra cultura
                                        </MenuItem>
                                    </Link>
                                    <Link to="/nosotros/equipo" style={{ textDecoration: "none", color: "black" }}>
                                        <MenuItem onClick={handleCloseNosotros}>
                                            Nuestro equipo
                                        </MenuItem>
                                    </Link>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}