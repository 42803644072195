import React from 'react'
import ServiciosManantial from '../../components/servicios_manantial/ServiciosManantial'

export const Servicios = () => {
    return (
        <div>
            <ServiciosManantial></ServiciosManantial>
        </div>
    )
}
