import config from "./config"


const ContactServices = {
	
	sendContactEmail : (bodyData) => new Promise (
		(resolve, reject) => {
			config.post("/contacto/formulario", bodyData)
			.then(
				data => resolve(data)
			)
			.catch(
				err => reject(err)
			)
		}
	)
    
}

export default ContactServices
