import React from "react"
import { Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import proposito from "../../assets/images/nosotros_cultura/proposito.svg"


const useStyles = makeStyles((theme) => ({
    purposeContent: {
        padding: "20px",
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        },
    },
    purposeIcon: {
        padding: "20px",
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right'
        },
    },
}));

export default () => {
	const classes = useStyles();

	return (
		<div style={{ paddingTop: "40px", paddingBottom: "40px", backgroundColor: "var(--aws-ligth)" }}>
			<Container>

				<Grid container direction="row">

		            <Grid className={classes.purposeContent} item lg={8} md={8} sm={6} xs={12}>
		               <Grid item lg={12} md={12} sm={12} xs={12}>
			               <Typography className="font-raleway-bold" variant='h5'>
			            		<label style={{ color: 'var(--rojo)' }}>
			            			Nuestro Propósito
			            		</label>
			               </Typography>
			            </Grid>
			            <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: "20px" }}>
			               <Typography className="font-raleway" variant='h5'>
			            		<label style={{ color: 'azul' }}>
			            			Mejorar el desempeño empresarial de nuestros clientes usando la tecnología y las soluciones innovadoras como ventaja comparativa.
			            		</label>
			               </Typography>
			            </Grid>
		            </Grid>

		            <Grid className={classes.purposeIcon} item lg={4} md={4} sm={6} xs={12}>
		               <img src={ proposito } alt="proposito"></img>
		            </Grid>


	            </Grid>

			</Container>
		</div>
	)
}