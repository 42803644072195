import React from 'react'
import Cabecera from '../../components/cabecera/Cabecera'
import aws from '../../../src/assets/images/servicios_aws/aws.svg'
import InformacionServicios from '../../components/aws/servicios/InformacionServicios'

export const ServiciosAWS = () => {
    return (
        <>
            <Cabecera
                titulo={[
                    "Servicios de ", <label key="titulo_servicios_aws" style={{ color: 'var(--rojo)' }}>AWS</label>
                ]}
                icono={aws}
                descripcion="Experiencia y genialidad en acción"
            />
            <InformacionServicios/>
        </>
    )
}
