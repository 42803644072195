export const VIDEOS = [
  {
    id: 1,
    icono: require("../../../../../assets/images/servicios_aws/rds/administracion.svg"),
    titulo: "Administración sencilla",
    descripcion: "Acceso a las capacidades de una base de datos relacional lista para la producción en cuestión de minutos. No es necesario aprovisionar infraestructura ni instalar o mantener software de base de datos.",
    url: "https://aws.amazon.com/es/rds/features/#administration"
  },
  {
    id: 2,
    icono: require("../../../../../assets/images/servicios_aws/rds/escalable.svg"),
    titulo: "Muy escalable",
    descripcion: "Escalar los recursos informáticos y de almacenamiento de su base de datos con  unos clics o una llamada a la API, una o más réplicas de lectura para eliminar el tráfico de lectura de la instancia de base de datos principal.",
    url: "https://aws.amazon.com/es/rds/details/#scalability"
  },
  {
    id: 3,
    icono: require("../../../../../assets/images/servicios_aws/rds/durabilidad.svg"),
    titulo: "Disponibilidad y durabilidad",
    descripcion: "Aprovisiona una instancia de base de datos Multi-AZ, Amazon RDS replica los datos de forma sincrónica a una instancia en espera de una zona de disponibilidad (AZ) diferente.",
    url: "https://aws.amazon.com/es/rds/details/#ha"
  },
  {
    id: 4,
    icono: require("../../../../../assets/images/servicios_aws/rds/rapidez.svg"),
    titulo: "Rapidez",
    descripcion: "Puede elegir entre dos opciones de almacenamiento con respaldo SSD: una optimizada para aplicaciones OLTP de alto rendimiento y otra para un uso general rentable.",
    url: "https://aws.amazon.com/es/rds/details/#performance"
  },
  {
    id: 5,
    icono: require("../../../../../assets/images/servicios_aws/rds/seguridad.svg"),
    titulo: "Seguridad",
    descripcion: "instancias de base de datos en Amazon Virtual Private Cloud (Amazon VPC), con lo que puede aislar las instancias de bases de datos y conectarse a su infraestructura de TI existente mediante una VPN con IPsec cifrada estándar del sector.",
    url: "https://aws.amazon.com/es/rds/details/#security"
  },
  {
    id: 6,
    icono: require("../../../../../assets/images/servicios_aws/rds/asequibilidad.svg"),
    titulo: "Asequibilidad",
    descripcion: "La opción de precios bajo demanda sin inversiones iniciales ni compromisos a largo plazo, o incluso tarifas por hora más bajas a través de nuestra opción de precios de instancias reservadas.",
    url: "https://aws.amazon.com/es/rds/details/#cost-effectiveness"
  },
];
