import React from 'react'
import Cabecera from '../cabecera/Cabecera'
import Empresa from '../../assets/images/informacion_empresa/Empresa.svg'
import { CardsInformacion } from './CardsInformacion'
import { Container, Typography, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px',
            minHeight: '96px'
        },
        [theme.breakpoints.up('md')]: {
            width: '800px',
            minHeight: '96px'
        },
        [theme.breakpoints.up('lg')]: {
            width: '800px',
            minHeight: '96px'
        }
    },
}))

export const InformacionEmpresa = () => {
    const classes = styles();
    return (
        <>
            <Cabecera
                titulo={[
                    <label key="titulo_informacion_empresa" style={{ color: 'var(--rojo)' }}>Nuestra</label>, " Empresa"
                ]}
                icono={Empresa}
                descripcion="Diversión, trabajo y aprendizaje"
            />
            <CardsInformacion />
            <Container className={classes.container}>
                <Typography variant="h6" className="font-raleway" style={{ fontDisplay:"auto", textAlign: 'center', padding: '60px 20px 60px 20px' }}>
                    <span style={{ color: 'var(--rojo)', fontWeight: 'bold' }}>Fluímos contigo</span>...
                        Esta frase refleja nuestro sentido de existencia como empresa:
                        “Estar siempre al lado de nuestros colaboradores y socios de negocio” para apoyarlos
                        en sus necesidades y cumplir con sus objetivos. Este acompañamiento lo realizamos mediante
                        la empatía hacia ellos, porque si nos ponemos en sus “zapatos” podemos identificar de modo más
                        rápido sus necesidades para apoyarlos con la solución.
                    </Typography>
            </Container>
        </>
    )
}

