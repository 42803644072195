import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Typography,
    Button,
    CardMedia,
  } from "@material-ui/core";
  import streaming from "../../../assets/images/casos_de_exito/streaming.png";
  import React from "react";
    import {Link} from "react-router-dom";

  
  export const CardCasoStreaming = (props) => {
    return (
      <Card
        style={{
          backgroundColor: "#232F3E",
          margin: "auto",
          minWidth: "200px",
          maxWidth: "300px",
        }}
      >
        <CardActionArea>
          <CardMedia
            style={{ height: "180px" }}
            image={streaming}
            title="Transmitir y compartir momentos"
          />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{fontDisplay:"auto", color: "white" }}
            >
              Con la llegada de la pandemia de COVID-19 las familias tuvieron que volverse mucho 
              más fuertes, poder reunirse, compartir en la mesa y más, pero esto se volvió algo 
              prácticamente imposible..
              <br />
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions
          style={{
            maxHeight: "40px",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Link
            to="/aws/casos-exito/trasmitir-y-compartir-momentos"
            style={{
              backgroundColor: "var(--rojo)",
              color: "white",
              padding: "4px 8px",
              borderRadius: "2px",
            }}
            size="small"
            color="primary"
          >
            <Button
            style={{
              backgroundColor: "var(--rojo)",
              color: "white",
              padding: "4px 8px",
              borderRadius: "2px",
            }}
            >
            Ver Más 
          </Button>
          </Link>
        </CardActions>
      </Card>
    );
  };
  