import { Container, Typography } from '@material-ui/core'
import React from 'react'
import bg from '../../../src/assets/images/cabecera/3.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Raleway, sans-serif',
        [theme.breakpoints.down('xs')]: {
            fontSize: '24px',
        }
    },
    description: {
        fontFamily: 'Raleway, sans-serif',
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px'
        }
    },
    img: {
        [theme.breakpoints.down('xs')]: {
            height: '100px',
            width: '100px'
        }
    }
}))

export default function Cabecera(props) {
    const classes = useStyles();
    return (
        <>
            <div style={{
                backgroundImage: "url(" + bg + ")",
                backgroundSize: 'cover',
                repeatY: 'none',
                minHeight: '380px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Container style={{ paddingTop: '100px' }}>
                    <Typography className={classes.title}  variant="h4" component="h2" style={{ color: '#fff', textAlign: 'center' }} >
                        {props.titulo}
                    </Typography>
                </Container>
                <div>
                    <img alt='icono' src={props.icono} className={classes.img} style={{ paddingTop: '30px' }}></img>
                </div>
                <Container>
                    <div style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                        <Typography className={classes.description} variant="h5" component="h2" style={{ color: '#fff', textAlign: 'center' }} >
                            {props.descripcion}
                        </Typography>
                    </div>
                </Container>
            </div>
        </>
    )
}
