import { Button, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import ContactServices from './../../services/ContactServices';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2'
import cluo from '../../assets/images/contacto/cluo.png';


const styles = makeStyles((theme) => ({
    root: {
        width:"100%"
    },
    comtainer: {
        [theme.breakpoints.down('xs')]: {
            width: '220px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '400px'
        }
    }
}));

const FomularioContacto = () => {
    const classes = styles();

    const [nombres, setNombres] = useState("")
    const [telefono, setTelefono] = useState("")
    const [email, setEmail] = useState("")
    const [mensaje, setMensaje] = useState("")
    const [nombresErr, setNombresErr] = useState({})
    const [telefonoErr, setTelefonoErr] = useState({})
    const [emailErr, setEmailErr] = useState({})
    const [mensajeErr, setMensajeErr] = useState({})

    const onSubmit = (e) => {
        e.preventDefault()
        const isValid = formValidation()
        if (isValid) {
            const dataForm = {
                Nombres: nombres,
                Telefono: parseInt(telefono),
                Email: email,
                Mensaje: mensaje
            }
            sendEmail(dataForm).then((resp) => {
                setNombres("")
                setTelefono("")
                setEmail("")
                setMensaje("")
            })
            sendEmail(dataForm).catch(error => {
                
            });
        }
    }

    const formValidation = () => {
        const fvNombresErr = {}
        const fvTelefonoErr = {}
        const fvEmailErr = {}
        const fvMensajeErr = {}
        let isValid = true

        if (nombres.trim().length < 1) {
            fvNombresErr.nombresShort = "Ingrese nombres y apellidos"
            isValid = false
        }

        if (telefono.length < 1) {
            fvTelefonoErr.nombresShort = "Ingrese número de teléfono"
            isValid = false
        }

        if (telefono.length >= 1 && telefono.length < 9) {
            fvTelefonoErr.nombresShort = "Número de teléfono inválido"
            isValid = false
        }

        if (email.trim().length < 1) {
            fvEmailErr.emailShort = "Ingrese correo electrónico"
            isValid = false
        }

        if (mensaje.trim().length < 1) {
            fvMensajeErr.mensajeShort = "Ingrese su mensaje por favor"
            isValid = false
        }

        setNombresErr(fvNombresErr)
        setTelefonoErr(fvTelefonoErr)
        setEmailErr(fvEmailErr)
        setMensajeErr(fvMensajeErr)
        return isValid
    }

    const sendEmail = async (userdata) => {
        await ContactServices.sendContactEmail(JSON.stringify(userdata))
            .then(resp => {
                if (!("statusCode" in resp.data)) {
                    Swal.fire({
                        imageUrl: cluo,
                        title: "Lo sentimos, no logramos enviar el correo",
                        confirmButtonColor: 'red',
                    })
                } else if (resp.data["statusCode"] !== 200) {
                    Swal.fire({
                        imageUrl: cluo,
                        title: resp.data["message"],
                        confirmButtonColor: 'red',
                    })
                } else {
                    Swal.fire({
                        imageUrl: cluo,
                        title: resp.data["message"],
                        confirmButtonColor: 'green',
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    imageUrl: cluo,
                    title: "Lo sentimos, no logramos enviar el correo",
                    confirmButtonColor: 'red',
                });
            })
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <form onSubmit={onSubmit}>
                <div className={classes.comtainer} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '20px', marginTop: '60px' }}>
                        <Typography className="font-raleway" style={{fontDisplay:"auto", color: '#FFFFFF', marginBottom: '10px' }}>Nombres y Apellidos</Typography>
                        <input
                            type="text"
                            value={nombres}
                            onChange={(e) => { setNombres(e.target.value) }}
                            className={classes.root}>
                        </input>
                        {Object.keys(nombresErr).map((key) => {
                            return <Typography key={key} style={{ color: "red" }}>{nombresErr[key]}</Typography>
                        })}
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <Typography className="font-raleway" style={{fontDisplay:"auto", color: '#FFFFFF', marginBottom: '10px' }}>Teléfono</Typography>
                        <input
                            type="number"
                            value={telefono}
                            onChange={(e) => { setTelefono(e.target.value) }}
                            className={classes.root}>
                        </input>
                        {Object.keys(telefonoErr).map((key) => {
                            return <Typography key={key} style={{ color: "red" }}>{telefonoErr[key]}</Typography>
                        })}
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <Typography className="font-raleway" style={{ color: '#FFFFFF', marginBottom: '10px' }}>Email</Typography>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                            className={classes.root}>
                        </input>
                        {Object.keys(emailErr).map((key) => {
                            return <Typography key={key} style={{ color: "red" }}>{emailErr[key]}</Typography>
                        })}
                    </div>
                    <div style={{ marginBottom: '30px' }}>
                        <Typography className="font-raleway" style={{fontDisplay:"auto", color: '#FFFFFF', marginBottom: '10px' }}>Mensaje</Typography>
                        <textarea
                            value={mensaje}
                            onChange={(e) => { setMensaje(e.target.value) }}
                            className={classes.root}
                            style={{ height: '150px', resize: 'none' }}>
                        </textarea>
                        {Object.keys(mensajeErr).map((key) => {
                            return <Typography key={key} style={{ color: "red" }}>{mensajeErr[key]}</Typography>
                        })}
                    </div>
                    <Button className={classes.root} type="submit" style={{ color: '#FFFFFF', backgroundColor: 'var(--rojo)', marginBottom: '60px' }}>Enviar</Button>
                </div>
            </form>
        </div>
    )
}

export default FomularioContacto
