import React from 'react'
import Cabecera from "../../components/cabecera/Cabecera"
import nosotros_equipo from '../../assets/images/nosotros_equipo/nosotros_equipo.svg'
import { NuestroEquipo } from '../../components/nosotros_equipo/NuestroEquipo'


export const Equipo = () => {
    return (
        <div>

            <Cabecera
                titulo={[
                    <label key="titulo_cultura" style={{ color: 'var(--rojo)' }}>Equipo</label>, " Manantial"
                ]}
                icono={nosotros_equipo}
                descripcion="Juntarse es un comienzo, seguir juntos es un progreso y trabajar juntos un éxito!!!"
            ></Cabecera>
            <NuestroEquipo />
        </div >
    )
}
