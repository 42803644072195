import { Container, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "50px",
  },
  heading: {
    fontSize: "17px",
    fontWeight: "bold",
  },
}));

export const InformacionCertificaciones = () => {
  const classes = useStyles();
  return (
    <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
      <Typography style={{fontDisplay:"auto", fontWeight: "bold", fontSize: "24px" }}>
        Conozca a detalle sobre las certificaciones
      </Typography>
      <div className={classes.root}>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Architect - Professional
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              El examen AWS Certified Solutions Architect - Professional
              (SAP-C01) está destinado a personas que desempeñar un rol
              profesional de arquitecto de soluciones. Este examen valida las
              habilidades técnicas avanzadas y la experiencia en diseñar
              aplicaciones y sistemas distribuidos en la plataforma AWS.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              DevOps Engineer - Professional
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              AWS Certified DevOps Engineer - Professional está destinado a
              personas con dos o más años de experiencia en el
              aprovisionamiento, operación y administración de entornos AWS.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Database - Specialty
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Obtenga una credencial reconocida por la industria de AWS que
              valida su experiencia en la amplitud de los servicios de bases de
              datos de AWS y acelera el uso de la tecnología de bases de datos
              para impulsar la transformación comercial de su organización. Cree
              credibilidad y confianza al resaltar su capacidad para diseñar,
              recomendar y mantener la solución óptima de la base de datos de
              AWS para un caso de uso.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Security - Specialty
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              La certificación AWS Certified Security – Specialty está destinada
              a individuos que ocupan un cargo vinculado con la seguridad y
              tienen, como mínimo, dos años de experiencia práctica en la
              protección de cargas de trabajo de AWS.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Data Analytics - Specialty
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Insignia de AWS Certified Data Analytics - Specialty está
              destinado a personas con experiencia y conocimientos que trabajan
              con servicios de AWS para diseñar, crear, proteger y mantener
              soluciones de análisis.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Architect - Associate
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              El examen AWS Certified Solutions Architect - Associate está
              destinado a personas que desempeñan la función de arquitecto de
              soluciones y tienen uno o más años de experiencia práctica en el
              diseño de sistemas distribuidos escalables, tolerantes a errores,
              rentables y disponibles en AWS.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Developer - Associate
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              El examen AWS Certified Developer - Associate está dirigido a
              personas que desempeñen un cargo de desarrollador y que tengan uno
              o más años de experiencia práctica en el desarrollo y el
              mantenimiento de aplicaciones basadas en AWS.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              SysOps Administrator - Associate
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              El examen AWS Certified SysOps Administrator - Associate acredita
              que un candidato tiene la experiencia y las habilidades para
              contribuir y “comenzar con el pie derecho” dentro de un equipo que
              da soporte a un entorno de la nube de AWS. Los candidatos que
              consigan la credencial deberían ser capaces de participar
              rápidamente en una organización con una presencia de AWS y llevar
              a cabo las tareas de trabajo que se esperan de alguien con uno o
              dos años de experiencia brindando soporte a una variedad de cargas
              de trabajo informáticas
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Cloud Practitioner - Foundational
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              El examen AWS Certified Cloud Practitioner está destinado a
              individuos que tengan las habilidades y el conocimiento necesarios
              para demostrar efectivamente una comprensión general del
              funcionamiento de la nube de AWS, independientemente de los roles
              técnicos específicos abordados por otras certificaciones de AWS.
              El examen se puede rendir en un centro examinador o desde la
              comodidad y la conveniencia del hogar o la oficina como un examen
              supervisado en línea.
              <br />
              <br />
              Conseguir esta certificación es un paso opcional aunque
              recomendado para alcanzar una certificación de nivel Associate o
              Specialty.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
};
