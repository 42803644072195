import React from "react";
import { Container } from "@material-ui/core";
import streaming from "../../../../src/assets/images/casos_de_exito/streaming.png";
import arquitectura_streaming from "../../../../src/assets/images/casos_de_exito/arquitectura-streaming.jpeg";
import Grid from "@material-ui/core/Grid";
import { CabeceraPublicacion } from "../../../components/aws/casos_exito/CabeceraPublicacion";

export const CasoStreaming = () => {
  return (
    <div>
      <div
        style={{
          paddingTop: "60px",
          backgroundColor: "var(--plomo-oscuro)",
          paddingBottom: "30px",
        }}
      >
        <Container>
          <Grid container direction="row" justifyContent="center">
            <Grid item sm={9} xs={12} style={{ padding: "60px 30px" }}>
              <CabeceraPublicacion
                img={streaming}
                titulo="Transmitir y compartir momentos"
                contenido="Viernes, 13 de enero del 2023"
              />
              <div>
                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                    fontDisplay:"auto",
                  }}
                >
                  Resumen ejecutivo
                </p>
                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                  Nuestro cliente es una organización con más de 20 años de 
                  experiencia brindando soluciones integrales de sepelio, orientado 
                  a ofrecer en todo momento, confianza, compromiso y un servicio de 
                  calidad basándose en las tendencias digitales. El desafío era integrar 
                  a las familias por medio de los canales digitales, con el fin de 
                  brindar el último adiós a sus seres queridos dado el inicio de la 
                  pandemia, la cual se presentó a nivel mundial. 
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Desafío del cliente
                </p>
                <p style={{fontDisplay:"auto", textAlign: "justify" }}>
                  Con la llegada de la pandemia de COVID-19 las familias tuvieron 
                  que volverse mucho más fuertes, poder reunirse, compartir en la 
                  mesa y más, pero esto se volvió algo prácticamente imposible. Lamentablemente, 
                  esta enfermedad causó mucho daño, los clientes no podían reunirse por última 
                  vez y dar el último adiós a sus seres queridos, dadas las restricciones de 
                  ley, las cuales fueron impuestas por seguridad y la salud colectiva de los 
                  ciudadanos, lo cual impedía brindar el mejor servicio a sus clientes en el 
                  momento que más se requería. Ante esto, era necesario brindar un nuevo 
                  canal, dicho canal debía permitir a las familias presenciar este último 
                  adiós, poder tener un recuerdo, sin importar la distancia y dejar plasmado 
                  este evento no solo en sus corazones, también en sus dispositivos. 
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Por qué AWS
                </p>
                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                  Dado la gran necesidad que aborda nuestro cliente, se decide 
                  usar AWS dado que cuenta con soluciones de streaming de video 
                  en vivo para entregar contenido multimedia de manera rentable 
                  a una audiencia global. Además, porque estas soluciones permiten 
                  construir una arquitectura de alta disponibilidad que ofrece 
                  una experiencia de visualización fiable en tiempo real. Estas 
                  fueron algunas de las razones por la cual el cliente decide 
                  usar AWS. 
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Socio
                </p>
                <p style={{fontDisplay:"auto", textAlign: "justify" }}>
                  Manantial Tecnológico es elegido por la amplia experiencia brindando soluciones TI 
                  en entornos cloud de manera ágil, eficiente y creativa, apostamos por soluciones 
                  innovadoras demostrando potencial, calidad y compromiso en nuestros servicios. Nos 
                  enfocamos en atender las necesidades de nuestros clientes aportando valor a nuestras 
                  soluciones para la satisfacción de nuestros clientes. 
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Solución del socio
                </p>

                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                  La arquitectura de solución propuesta contempla la conexión y 
                  transmisión de video en vivo por medio de cámaras ubicadas en 
                  sitios estratégicos de los distintos responsos, la forma en 
                  cómo se realiza el procesamiento del video es mediante el servicio 
                  AWS Elemental MediaLive el cual nos permite crear streamings 
                  de alta calidad. Posterior a ello, realizamos la distribución 
                  del mismo con el servicio AWS Elemental MediaPackage de esta 
                  manera se logra cubrir esos momentos importantes para los 
                  clientes. 
                  <br></br>
                  <br></br>
                  Parte de la solución era considerar la posibilidad de que los 
                  clientes puedan revivir nuevamente dicho momento cuando lo 
                  deseen, ante esto la solución debía ser capaz de cubrir el escenario 
                  donde el contenido pueda ser solicitado a demanda, para lograrlo se 
                  implementó AWS Elemental MediaConvert con este servicio realizamos 
                  la conversión del contenido a un formato que permita su distribución 
                  en sitios web. Dentro de este proceso consideramos importante contar 
                  con un almacenamiento capaz de soportar cualquier volumen de datos, lo 
                  cual fue cubierto con el servicio de AWS S3.  
                  <br></br>
                  <br></br>
                  Como apartado final era necesario contar con una interfaz que permita 
                  poder integrarse con otros sistemas, esta necesidad fue cubierta con 
                  el servicio de AWS API Gateway en conjunto con el servicio AWS 
                  DynamoDB, de esta manera se logró responder diversas consultas 
                  con tiempos menores al segundo. Finalmente, cubrimos el escenario 
                  de video a demanda logrando concretar todas las necesidades y desafíos 
                  presentados por el cliente haciendo uso de servicios serversless, brindando 
                  alta disponibilidad y con un performance excelente. 
                </p>

                <img alt="arquitectura Cloud"
                  src={arquitectura_streaming}
                  style={{
                    width: "100%",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                ></img>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
