import { Container, Typography } from "@material-ui/core";
import React from "react";
import { CardPublicacion } from "./CardPublicacion";
import Grid from "@material-ui/core/Grid";
import { CardCasoScaling } from "./CardCasoScaling";
import { CardCasoStreaming } from "./CardCasoStreaming";
import { CardCasosConnect } from "./CardConnect";

export const Publicaciones = () => {
  return (
    <div
      style={{
        paddingTop: "40px",
        paddingBottom: "60px",
        backgroundColor: "var(--plomo-oscuro)",
      }}
    >
      <div
        style={{
          textAlign: "center",
          paddingTop: "20px",
          paddingBottom: "40px",
        }}
      >
        <Typography
          variant="h5"
          className="font-sora"
          style={{fontDisplay:"auto", fontWeight: "bold" }}
        >
          Últimas publicaciones
        </Typography>
      </div>
      <Container>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            style={{ textAlign: "center", padding: "20px" }}
          >
            <CardPublicacion></CardPublicacion>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            style={{ textAlign: "center", padding: "20px" }}
          >
            <CardCasoScaling />
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            style={{ textAlign: "center", padding: "20px" }}
          >
            <CardCasoStreaming />
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            style={{ textAlign: "center", padding: "20px" }}
          >
            <CardCasosConnect />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
