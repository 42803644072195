import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import facial from '../../assets/images/experiencia/facial.svg';
import { Button, CardActions } from '@material-ui/core';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        minHeight: 250,
        backgroundColor: '#232F3E'
    },
});

export default function Facial() {
    const classes = useStyles();

    return (
        <Card className={classes.root} style={{ marginTop: '80px' }}>
            <CardActionArea>
                <center>
                <img alt='facial' style={{paddingTop: '20px', margin: 'auto'}} src={facial} width="74" height="74"></img >
                </center>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2" style={{ fontDisplay:"auto", textAlign: 'center', color: 'var(--rojo)' }}>
                        Reconocimiento facial
                    </Typography>
                    <Typography variant="body2" component="p" style={{fontDisplay:"auto", textAlign: 'center', color: '#ffffff' }}>
                        La disponibilidad de su información es lo que nos motiva para brindarle el mejor servicio.
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Link to="/facial" style={{textDecoration: 'none'}}>
                    <Button size="small" color="primary" style={{marginTop: '28px', marginBottom: '8px', backgroundColor: 'var(--rojo)', color: 'white'}}>
                        Explorar
                    </Button>
                </Link>
            </CardActions>
        </Card>
    );
}