import React from 'react'
import Cabecera from "../../components/cabecera/Cabecera"
import nuestros_clientes from '../../assets/images/clientes/nuestros_clientes.svg'
import { NuestrosClientes } from '../../components/clientes/NuestrosClientes'

export const Clientes = () => {
    return (
        <div>

            <Cabecera 
                titulo={[
                    <label key="titulo_cultura" style={{ color: 'var(--rojo)' }}>Clientes</label>, " Manantial"
                ]}
                icono={ nuestros_clientes }
                descripcion="El objetivo como empresa no es tener solamente el mejor servicio al cliente, sino que sea legendario"
            ></Cabecera>
            <NuestrosClientes></NuestrosClientes>
            
        </div >
    )
}
