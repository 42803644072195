import { Container, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '50px'
    },
    heading: {
        fontSize: '17px',
        fontWeight: 'bold',
    },
}));

export const DetalleLambda = () => {
    const classes = useStyles();
    return (
        <Container style={{ marginTop: '50px', marginBottom: '50px' }}>
            <Typography style={{fontDisplay:"auto", fontWeight: 'bold', fontSize: '24px' }}>
                ¿Donde hacer uso?
            </Typography>
            <div className={classes.root}>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Procesamiento de datos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Puede usar AWS Lambda para ejecutar código en respuesta a desencadenadores, como cambios en los datos,
                            cambios en el estado del sistema o acciones de los usuarios. Lambda se puede activar directamente mediante
                            servicios de AWS como S3, DynamoDB, Kinesis, SNS y CloudWatch, se puede conectar a los sistemas de archivos
                            existentes de EFS o se puede organizar en flujos de trabajo mediante AWS Step Functions. Esto permite crear
                            una variedad de sistemas de procesamiento de datos sin servidor en tiempo real.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Procesamiento de archivos en tiempo real</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Puede usar Amazon S3 para activar AWS Lambda y procesar los datos inmediatamente después de cargarlos. También puede
                            conectarse directamente a un sistema de archivos de Amazon EFS existente, lo que permite un acceso compartido paralelo
                            de forma masiva para el procesamiento de archivos a gran escala. Por ejemplo, puede usar Lambda para crear imágenes en
                            miniatura, transcodificar videos, indexar archivos, procesar registros, validar contenido y agregar y filtrar datos en
                            tiempo real.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Procesamiento de transmisiones en tiempo real</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Es posible usar AWS Lambda y Amazon Kinesis para procesar datos de streaming en tiempo real con el objetivo de realizar seguimientos
                            de actividades de las aplicaciones, procesamientos de órdenes de transacciones, análisis de transmisiones de clics, limpieza de datos,
                            generación de métricas, filtrado de registros, indexación, análisis en redes sociales y mediciones y telemetría de datos de dispositivos
                            compatibles con IoT.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Machine learning</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Puede usar AWS Lambda para preprocesar datos antes de introducirlos a su modelo de aprendizaje automático. Con el acceso de Lambda a EFS,
                            también puede ofrecer su modelo de predicción a escala sin tener que aprovisionar ni administrar ninguna infraestructura.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Back-ends</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Puede crear backends sin servidores con AWS Lambda para administrar solicitudes web, móviles, de Internet de las cosas (IoT) y de API de
                            terceros. Aproveche los controles de rendimiento constante de Lambda, como las múltiples configuraciones de memoria y la simultaneidad aprovisionada,
                            para crear aplicaciones sensibles a la latencia a cualquier escala.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Aplicaciones web</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Al combinar AWS Lambda con otros servicios de AWS, los desarrolladores pueden compilar aplicaciones web de gran capacidad que adaptan su escala automáticamente
                            y se ejecutan con una configuración de alta disponibilidad en varios centros de datos, sin necesidad de realizar ninguna tarea administrativa en términos de
                            escalabilidad, copias de seguridad o redundancia con varios centros de datos.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Backends para IoT</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Podemos compilar back-ends sin servidores mediante el uso de AWS Lambda para administrar solicitudes web, móviles, de Internet de las cosas (IoT)
                            y de API de terceros.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Backends móviles</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            AWS Lambda facilita la creación de experiencias de aplicaciones personalizadas y completas. Puede crear backends con AWS Lambda y Amazon API Gateway para
                            autenticar y procesar las solicitudes de API. Utilice AWS Amplify para integrar de manera fácil el backend con los frontends
                            de iOS, Android, Web y React Native.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Container >
    )
}
