import {
  Grid,
  Container,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Beneficios } from "./Beneficios";
import { Demostraciones } from "./Demostraciones";
import { Funcionamiento } from "./Funcionamiento";
import { UseCase } from "./UseCase";

export const InformacionConnect = () => {
  return (
    <>
      <div
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
          backgroundColor: "var(--plomo-mas-oscuro)",
        }}
      >
        <Container>
          <Grid container justifyContent="space-between" spacing={4}>
            <Grid item md={6}>
              <div style={{ padding: "0px 50px" }}>
                <Typography
                  className="font-sora-bold"
                  style={{
                    color: "var(--rojo)",
                    marginBottom: "20px",
                    fontDisplay:"auto",
                  }}
                  variant="h5"
                >
                  Amazon Connect
                </Typography>
                <Typography
                  className="font-raleway"
                  style={{ fontDisplay:"auto", color: "FFFFF", fontWeight: 550 }}
                  variant="h6"
                >
                  Empresas de todos los tamaños y sectores están migrando sus
                  centros de contacto a Amazon Connect para aumentar la
                  satisfacción de los clientes, acelerar el tiempo de
                  innovación, mejorar la productividad de los agentes y reducir
                  los costos
                </Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <div style={{ padding: "0px 50px" }}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/XeVkV4_YnMA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ maxWidth: "100%", minHeight: "315px" }}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Funcionamiento />
      {/*   <ComoEmpezar /> */}
      <Demostraciones />
      <UseCase />
      <Beneficios />
    </>
  );
};
