import React from "react";
import { Container } from "@material-ui/core";
import scaling from "../../../../src/assets/images/casos_de_exito/scaling.png";
import arquitectura from "../../../../src/assets/images/casos_de_exito/arquitectura.png";
import Grid from "@material-ui/core/Grid";
import { CabeceraPublicacion } from "../../../components/aws/casos_exito/CabeceraPublicacion";

export const CasoScaling = () => {
  return (
    <div>
      <div
        style={{
          paddingTop: "60px",
          backgroundColor: "var(--plomo-oscuro)",
          paddingBottom: "30px",
        }}
      >
        <Container>
          <Grid container direction="row" justifyContent="center">
            <Grid item sm={9} xs={12} style={{ padding: "60px 30px" }}>
              <CabeceraPublicacion
                img={scaling}
                titulo="La escalabilidad del negocio"
                contenido="Jueves, 23 de diciembre del 2021"
              />
              <div>
                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Resumen ejecutivo
                </p>
                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                  Nuestro cliente Delosi es un operador de franquicias con más
                  de 40 años en el mercado, lidera marcas reconocidas a nivel
                  mundial, cuenta con presencia en Perú y Bolivia, con especial
                  foco en los canales digitales por el contexto actual. El
                  desafío era garantizar la disponibilidad y calidad de la
                  plataforma de los canales digitales, proponiendo así una
                  solución que sea escalable y se adapte a la demanda de los
                  clientes y de la organización.
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Desafío del cliente
                </p>
                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                  La baja satisfacción de un cliente para una organización es un
                  gran problema, teniendo en cuenta que, así como las
                  recomendaciones llegan rápidamente también ocurre lo mismo con
                  las quejas por el mal servicio recibido. Por lo tanto, es
                  importante garantizar la disponibilidad y la calidad en el
                  servicio entregado, que va de la mano con el crecimiento
                  exponencial de la organización. Siendo el canal digital el
                  principal negocio de las diferentes organizaciones y ante la
                  situación actual, dichas organizaciones deberán garantizar los
                  servicios que interactúan los clientes y este deberán estar
                  disponibles. Nuestro cliente tenía la información de sus
                  canales digitales consolidada en algunas bases de datos, lo
                  cual generaba un riesgo a nivel de negocio por la dependencia
                  de varios e-commerce de una sola base de datos. El RDS podría
                  llegar al máximo de consumo de CPU y/o memoria, esto causaría
                  que todos sus procesos estén expuestos a fallas, generando
                  pérdidas en el negocio.
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Por qué AWS
                </p>
                <p style={{fontDisplay:"auto", textAlign: "justify" }}>
                  Dado la gran necesidad que aborda nuestro cliente, se decide
                  usar AWS por los beneficios de los servicios que ofrece por
                  ejemplo alta disponibilidad de sus servicios, infraestructura
                  global, servicios completamente administrados por AWS, pago
                  por uso. Estas fueron algunas de las razones por lo que optó
                  el cliente para decidir usar AWS, así como también se
                  garantizaría la calidad de su servicio.
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Socio
                </p>
                <p style={{fontDisplay:"auto", textAlign: "justify" }}>
                  Manantial Tecnológico es elegido mediante un proceso concursal
                  por la amplia experiencia y certificación brindando soluciones
                  TI, de manera ágil, eficiente y creativa, apuesta por
                  soluciones innovadoras demostrando potencial, calidad y
                  compromiso en sus servicios además estamos especializados
                  principalmente en los servicios de Cloud y Base de Datos.
                  Tenemos la elección de los clientes a raíz de su problemática
                  además de nuestro personal comprometido y especializado en
                  diversas áreas de tecnología mostrando eficiencia en nuestros
                  servicios y soluciones brindadas.
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Solución del socio
                </p>

                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                  Para el desarrollo de la implementación primero, elaboramos
                  una planificación del proyecto con una duración de 2 meses,
                  luego de ello se procedió con la ejecución del proyecto en un
                  ambiente no productivo, después de realizar todas las
                  validaciones necesarias, como fase final se implementó en el
                  ambiente productivo. Este proyecto resolvió la problemática
                  del cliente, debido a que su arquitectura actual esfiable,
                  escalable y optima siendo estos los componentes principales de
                  la solución, debido a que se estarían eliminando los impactos
                  en su negocio. Sobre el diseño de la solución se planteó una
                  arquitectura híbrida y desacoplada, esta arquitectura
                  garantiza la disponibilidad del negocio independientemente de
                  algún evento en el lado on-premise. La arquitectura planteada
                  debe responder a las necesidades del negocio provisionando los
                  recursos según la demanda. Al implementar esta arquitectura se
                  desplegó la capa de aplicación en al menos 2 zonas de
                  disponibilidad, se usó un balanceador para la distribución del
                  tráfico y utilizamos el servicio de AWS WAF para controlar el
                  tráfico desde la perspectiva de seguridad. La capa de datos se
                  encuentra desplegada sobre el servicio de RDS, cada marca
                  cuenta con un RDS independiente dejando en el pasado la
                  arquitectura acoplada y unificada. Así como también para a el
                  monitoreo de los recursos, se hace uso del servicio de
                  Cloudwatch, donde cada recurso tiene configurado un tópico de
                  SNS para su notificación al usuario encargado de la gestión en
                  caso se presente un evento que pudiera generar un riesgo en el
                  negocio, para las alertas críticas se realizo una integración
                  con Amazon Connect para generar llamadas telefónicas a nuestro
                  equipo de soporte. Tambien se aprovecha los servicios de AWS
                  con el ambiente onpremise mediante el monitoreo y respuesta
                  automatizadas esto mediante los servicios de System Manager,
                  Cloudwatch, SNS y Lambda.
                </p>

                <img alt="Arquitectura Cloud"
                  src={arquitectura}
                  style={{
                    width: "100%",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                ></img>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
