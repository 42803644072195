import React from 'react'
import Cabecera from '../../components/cabecera/Cabecera'
import Proximos from '../../assets/images/proximos_pasos/Proximos.svg'
import { Contenido } from '../../components/aws/proximos_pasos/Contenido.js'
import { InformacionManantial } from '../../components/aws/proximos_pasos/InformacionManantial.js'

export const ProximosPasos = () => {
    return (
        <>
            <Cabecera
                titulo={[
                    "Nuestros ", <label key="titulo_proximos_pasos" style={{ color: 'var(--rojo)' }}>próximos</label>, " pasos con ", <label key="titulo_cultura" style={{ color: 'var(--rojo)' }}>AWS</label>
                ]}
                icono={Proximos}
                descripcion="Procuramos superarnos y seguir avanzando"
            />
            <Contenido/>
            <InformacionManantial/>
        </>
    )
}
