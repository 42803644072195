import { Container, Typography } from '@material-ui/core'
import React from 'react'
import lambda from '../../../../assets/images/servicios_aws/lambda.svg'
import arquitectura_lambda from '../../../../assets/images/servicios_aws/arquitectura_lambda.svg'
import { DetalleLambda } from './DetalleLambda'

export const InformacionLambda = () => {
    return (
        <div style={{ marginTop: '50px', marginBottom: '50px' }}>
            <Container>
                <div>
                    <Typography style={{fontDisplay:"auto", fontSize: '24px', fontWeight: 'bold' }}>
                        ¿Que es?
                    </Typography>
                    <Container>
                        <Typography style={{fontDisplay:"auto", marginTop: '20px', textAlign: 'center' }}>
                            AWS Lambda es un servicio informático sin servidor que le permite ejecutar código sin aprovisionar
                            ni administrar servidores, crear una lógica de escalado de clústeres basada en la carga de trabajo,
                            mantener integraciones de eventos o administrar tiempos de ejecución. Con Lambda, puede ejecutar código
                            para casi cualquier tipo de aplicación o servicio backend sin tener que realizar tareas de administración.
                            Simplemente cargue su código como un archivo ZIP o una imagen de contenedor y Lambda asigna de manera automática
                            y precisa la potencia de ejecución informática y ejecuta el código en función de la solicitud o el evento entrante
                            para cualquier escala de tráfico.
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img alt='Amazon Lambda' style={{ height: '100px', width: '100px', marginTop: '20px' }} src={lambda} />
                        </div>
                    </Container>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <Typography style={{fontDisplay:"auto", fontSize: '24px', fontWeight: 'bold' }}>
                        ¿Como funciona?
                    </Typography>
                    <Container>
                        <Typography style={{ fontDisplay:"auto", marginTop: '20px', textAlign: 'center' }}>
                            Puede configurar su código para que se active automáticamente desde otros 200 servicios de AWS y aplicaciones de SaaS o puede
                            llamarlo directamente desde cualquier aplicación web o móvil. Puede escribir funciones de Lambda en su lenguaje favorito
                            (Node.js, Python, Go, Java y más) y usar herramientas de contenedor y sin servidor, como AWS SAM o la CLI de Docker, para
                            compilar, probar e implementar las funciones.
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <img alt='Arquitectura de Lambda' style={{ minHeight: '200px', marginTop: '30px', maxWidth: '100%'}} src={arquitectura_lambda} />
                        </div>
                    </Container>
                </div>
                <div style={{ marginTop: '60px' }}>
                    <DetalleLambda />
                </div>
            </Container>
        </div>
    )
}
