import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import database from "../../assets/images/servicios/database.svg";
import check from "../../assets/images/servicios_manantial/check.svg";

import UseStyles from "./UseStyless";

export default function DataBase() {
  const classes = UseStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea style={{ height: "100%" }}>
        <img alt="Bases de Datos" className={classes.rooter} src={database}></img>
        <CardContent style={{ paddingBottom: "auto" }}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ fontDisplay:"auto", textAlign: "center", color: "var(--rojo)" }}
          >
            Base de datos
          </Typography>
          <Typography
            variant="body2"
            component="p"
            style={{fontDisplay:"auto", textAlign: "center", color: "white" }}
          >
            La disponibilidad de su información es lo que nos motiva para
            brindarle el mejor servicio.
          </Typography>
          <br></br>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Implementación de bases de datos.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Upgrade y Migración.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Backup y Recovery.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Afinamiento de Base de Datos.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Gestión de Base de Datos.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Bolsa de horas.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Capacitación.
            </Typography>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
