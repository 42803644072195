import React from 'react'
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

export default function Aws() {
    const classes = useStyles();
    const [open, setOpenAws] = React.useState(false);
    const anchorRef = React.useRef(null);

    function handleToggleAws() {
        setOpenAws((openvalueAws) => !openvalueAws);
    };

    function handleCloseAws (event) {
        if (anchorRef.current?.contains(event.target)) {
            return;
        }

        setOpenAws(false);
    };

    function handleListKeyDownAws(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenAws(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleAws}
                    style={{color: 'white', minWidth: '56px', fontFamily: 'Raleway', textTransform: 'capitalize'}}
                >
                    AWS
        </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseAws}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDownAws}>
                                        <Link to="/aws/nuestro-compromiso" style={{ textDecoration:"none", color: "black" }}>
                                            <MenuItem onClick={handleCloseAws}>
                                                Nuestro compromiso con AWS
                                            </MenuItem>
                                        </Link>
                                        <Link to="/aws/proximos-pasos" style={{ textDecoration:"none", color: "black" }}>
                                            <MenuItem onClick={handleCloseAws}>
                                                Próximos pasos
                                            </MenuItem>
                                        </Link>
                                        <Link to="/aws/casos-exito" style={{ textDecoration:"none", color: "black" }}>
                                            <MenuItem onClick={handleCloseAws}>
                                                Casos de éxito
                                            </MenuItem>
                                        </Link>
                                        <Link to="/aws/servicios" style={{ textDecoration:"none", color: "black" }}>
                                            <MenuItem onClick={handleCloseAws}>
                                                Servicios de AWS
                                            </MenuItem>
                                        </Link>
                                        <Link to="/aws/blog" style={{ textDecoration:"none", color: "black" }}>
                                            <MenuItem onClick={handleCloseAws}>
                                                Blog
                                            </MenuItem>
                                        </Link>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}