import React from "react"
import { Container, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

export default () => {
	return (
		<div style={{ paddingTop: "40px", paddingBottom: "80px", backgroundColor: "var(--aws-ligth)" }}>
			<Container>

				<Grid container direction="row">

					<Grid item xs={12} style={{ padding: "20px", textAlign: "center" }}>
						<Typography className="font-raleway" variant='h5' style={{ color: 'var(--azul)' }}>
							En un mundo donde las organizaciones y el mercado cambian constantemente, trabajamos y nos capacitamos 
							para ser una <label style={{ color: "var(--rojo)", fontWeight: "bold" }}>organización, ágil, líquida y 
							que fluye ( Manantial )</label>, buscamos soluciones creativas y de impacto, siendo conscientes 
							de que las personas son lo más importante. En Manantial buscamos el equilibrio entre el 
							trabajo y el bienestar de nuestra gente, buscando siempre crear un ambiente 
							de <label style={{ color: "var(--rojo)", fontWeight: "bold" }}>compañerismo, confianza, 
							humor</label> y donde podamos desarrollar 
							esa <label style={{ color: "var(--rojo)", fontWeight: "bold" }}>pasión por lo que hacemos</label>.
						</Typography>
					</Grid>

				</Grid>

			</Container>
		</div>
	)
}