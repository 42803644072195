import React from 'react'
import Cabecera from "../../../components/cabecera/Cabecera"
import lambda from '../../../../src/assets/images/servicios_aws/lambda.svg'
import { InformacionLambda } from '../../../components/aws/servicios/lambda/InformacionLambda'

export const ServicioLambda = () => {
    return (
        <>
            <Cabecera
                titulo={[
                    "AWS ", <label key="titulo_lambda" style={{ color: 'var(--rojo)' }}>Lambda</label>
                ]}
                icono={lambda}
                descripcion="Ejecute código sin tener que pensar en los servidores."
            />
            <InformacionLambda />
        </>
    )
}
