import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import hands from '../../assets/images/servicios/hands.svg'
import { Button, CardActions } from '@material-ui/core';
import { Link } from "react-router-dom";
import UseStyles from './UseStyless';

export default function CardsOutsourcing() {
    const classes = UseStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <img alt='Outsourcing' className={classes.rooter} src={hands}></img>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2" style={{ fontDisplay:"auto", textAlign: 'center', color: 'var(--rojo)' }}>
                        Outsourcing
                    </Typography>
                    <Typography variant="body2" component="p" style={{ fontDisplay:"auto", textAlign: 'center' }}>
                        Ponemos a su disposición personas con vocación de servicios que comparten sus objetivos
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ maxHeight: '40px', alignItems: 'flex-end', justifyContent: 'center' }}>
                <Link to="/servicios" style={{textDecoration: 'none'}}>
                <Button  className={classes.button} size="small" color="primary" style={{ marginTop: '28px', width: '100%' }}>
                    Ver Más
                </Button>
                </Link>
            </CardActions>
        </Card>
    );
}