import React from "react";
import { Container } from "@material-ui/core";
import portadaConnect from "../../../../src/assets/images/casos_de_exito/portada-delosi-connect.jpg";
import arquitectura_connect_Delosi from "../../../../src/assets/images/casos_de_exito/Arquitectura-Connect-Delosi.png";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { CabeceraPublicacion } from "../../../components/aws/casos_exito/CabeceraPublicacion";
import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "50px",
  },
  heading: {
    fontSize: "17px",
    fontWeight: "bold",
  },
}));

export const CasoConnect = () => {
  const classes = useStyles();
  return (
    <div>
      <div
        style={{
          paddingTop: "60px",
          backgroundColor: "var(--plomo-oscuro)",
          paddingBottom: "30px",
        }}
      >
        <Container>
          <Grid container direction="row" justifyContent="center">
            <Grid item sm={9} xs={12} style={{ padding: "60px 30px" }}>
              <CabeceraPublicacion
                img={portadaConnect}
                titulo="Transformando experiencIAs en tu Contact Center con AWS Connect"
                contenido="Lunes, 12 de Agosto del 2024"
              />
              <div>
                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                    fontDisplay:"auto",
                  }}
                >
                  Resumen ejecutivo
                </p>
                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                  Nuestro cliente, un operador de franquicias con más de 40 años de experiencia, representa actualmente a varias marcas reconocidas globalmente, y lidera el mercado en Perú y Bolivia. Con un enfoque especial en los canales digitales debido al contexto actual, el objetivo de nuestro cliente era asegurar la disponibilidad y calidad de su plataforma digital. En línea con ese objetivo, MANANTIAL le propuso una solución escalable para satisfacer tanto las necesidades de los consumidores como las de la organización. 
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Desafío del cliente
                </p>
                <p style={{fontDisplay:"auto", textAlign: "justify" }}> 
                  El cliente enfrentaba una alta demanda en su central de pedidos, amplificando los retos relacionados con el diseño de experiencias de usuario, distribución de agentes, análisis de negocio y con esto la reducción de tiempos de atención para mejorar la calidad del servicio. El canal de voz, el más representativo y preferido por sus clientes, requería un cambio de enfoque, donde se pudieran priorizar esfuerzos en crear experiencias y no solo mantenerlas, hablamos de autogestión, mecanismos de segmentación, visibilidad a un nivel granular y nuevas formas de optimizar los flujos de atención.  
                  <br/>
                  Por otro lado, el consumidor experimentaba tiempos de respuesta muy altos, perjudicando así su experiencia sobre todo en el proceso compra, la posibilidad de comunicarse por otros canales digitales no le hacía sentido, debido a que las comunicaciones gestionadas no ofrecían características de omnicanalidad. La necesidad de atender en primera línea las solicitudes del consumidor, se tradujeron en flujos automatizados, mayor integración entre canales y opciones que agilicen la comunicación sobre todo en periodos de alta demanda, esto se resume en brindar una mejor experiencia al consumidor. 
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  ¿Por qué AWS?
                </p>
                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                Se decide usar AWS por los beneficios de los servicios que ofrece por ejemplo alta disponibilidad, infraestructura global, servicios completamente administrados por AWS, y pago por uso. Estas fueron algunos de los puntos claves considerados para decidir usar AWS, así como también la mejora asegurada de la calidad de su servicio, principalmente porque nuestro cliente transformó la experiencia de sus consumidores con Amazon Connect, un centro de contacto nativo de nube que incluye inteligencia artificial para incrementar la productividad de los agentes, realizar un seguimiento de las llamadas y establecer un enfoque omnicanal, dejando además todo un campo abierto para la innovación. 
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Socio
                </p>
                <p style={{fontDisplay:"auto", textAlign: "justify" }}>
                  Manantial Tecnológico es elegido mediante un proceso concursal por la amplia experiencia y certificación brindando soluciones TI, de manera ágil, eficiente y creativa, por su conocida apuesta por soluciones innovadoras demostrando potencial, calidad y compromiso en sus servicios, siendo la seguridad el pilar más importante en toda implementación propuesta y ejecutada. 
                  <br/>
                  Especializados en Amazon Connect, un servicio Cloud AWS, y que actualmente MANANTIAL hace uso propio uso y lo impulsa entre sus clientes debido a sus grandes ventajas para la construcción de un Contact Center con IA. Adicionalmente, el enfoque de MANANTIAL como uno de sus diferenciadores, partiendo del entendimiento de la problemática de negocio del cliente y, además por contar con profesionales comprometidos y especializados en diversas áreas de tecnología que incluyen desarrollo, analítica y arquitectura en entornos nativos y de nube híbrida, mostrando calidad y eficiencia en los servicios y soluciones que ofrece.  
                </p>

                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Solución del socio
                </p>

                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                  La implementación fue segmentada en 3 niveles cruciales, partiendo por plataforma, análisis y optimización:
                  <div className={classes.root}>
                    <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Plataforma
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Amazon Connect como plataforma ofrece una interfaz bastante completa y nativa de nube, con diversas opciones las cuales permiten a los agentes agilizar todo el proceso de atención, así como también del lado de la administración a gestionar diversos niveles el acceso para cada una de las funcionalidades. No obstante, nuestro cliente tenía un componente clave, su plataforma principal para la gestión de pedidos, donde su integración no era debatible, por tal motivo, haciendo uso de las interfaces de Connect se construyó un sitio web a la medida para abarcar no solo la atención, sino también, la comunicación instantánea y segura entre sistemas y ofrecer a la vez, fluidez al momento de gestionar llamadas salientes, empleando servicios de AWS como WAF, CloudFront, S3, DynamoDB, API Gateway y Lambda.                         
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Análisis
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Visibilidad es el término crucial cuando tenemos una solución de Contact Center y Amazon Connect está diseñado para eso. Esta capacidad permitió que exploremos con el cliente sus diversas opciones, tanto los paneles o dashboards, como la posibilidad de elaborar reportes a medida. Adicionalmente, por normativa nuestro cliente necesitaba almacenar la información por un periodo de retención de 2 años, entonces, haciendo uso de las opciones nativas de Connect se configuró con un almacén de datos de fácil acceso, seguro y con capacidades para generar insights de los clientes, por medio de la explotación de información con herramientas de análisis de negocio en la nube impulsadas con IA. Lo que era antes una posibilidad, hoy en día es una necesidad, y la decisión que no es impulsada por datos carece de impacto, por tanto, para complementar el análisis de negocio requerido, habilitamos también paneles personalizados con los registros de las aplicaciones, información vital para detectar comportamientos anómalos; todo lo anterior se logró haciendo uso de servicios como Amazon S3, Kinesis, CloudWatch y Quicksight. 
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Optimización
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Como último punto, pero no el menos importante, la optimización con una mirada 360, no solo de costos de infraestructura, sino de la experiencia y atención al cliente. Con las herramientas de análisis en marcha, los reportes empezaron a brindar frutos, traducido a tendencias, las cuales proporcionaron el punto de partida para que nuestro cliente pudiera enfocar una serie de estrategias por cada una de las opciones del IVR, como, por ejemplo, el estado del pedido de los consumidores, algo muy concreto, pero sumamente crítico desde el punto de vista del cliente, ya que puede resumir una llamada o contacto, ya sea en satisfacción o en molestia. Este flujo fue automatizado para brindar respuestas en segundos, reduciendo notablemente los tiempos de espera para dar información a los consumidores, por medio del establecimiento de integraciones con las interfaces de las aplicaciones del negocio y del diseño de un flujo de respuesta consistente utilizando el servicio serverless de AWS: Lambda. Esta implementación permitió que los atributos de contacto de un consumidor pudieran ser enviados durante el flujo de contacto o llamada, y así obtener el estado del pedido de un cliente o consumidor en tiempo real.  
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </p>
                <img alt="arquitectura Cloud"
                  src={arquitectura_connect_Delosi}
                  style={{
                    width: "100%",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                ></img>
                <p
                  className="font-raleway"
                  style={{
                    backgroundColor: "#232F3E",
                    color: "white",
                    marginTop: "15px",
                    padding: "5px",
                  }}
                >
                  Resultado
                </p>
                <p style={{ fontDisplay:"auto", textAlign: "justify" }}>
                  Nuestro cliente mantiene la idea constante de buscar e impulsar un cambio, porque el mercado está en constante movimiento, los consumidores buscan nuevas formas de estar al tanto de sus marcas favoritas, esperan novedades, no solo a nivel de los productos sino como los integran en su día a día, una experiencia cada vez mejor.  
                  <br/>
                  Este proyecto permitió que, bajo el enfoque propuesto por MANANTIAL, se obtengan los siguientes resultados:                 
                  <ul>
                    <li>
                      65% de aumento en la tasa de autoservicio, diseñar experiencias y generar reportes rápidamente con apenas unos clics. 
                    </li>
                    <li>
                      30% de reducción en el volumen de llamadas de los agentes, y una mayor participación del agente interacciones no repetitivas.
                    </li>
                    <li>
                      35 % de llamadas totalmente automatizadas, teniendo como principal caso de uso, la consulta de estado de pedido.
                    </li>
                  </ul> 
                  Estos resultados se han traducido en una mejor experiencia de servicio y un mayor nivel de satisfacción de los consumidores del cliente, quien explica estos resultados positivos para su negocio diciendo: “un equipo ágil, innovador y con las tecnologías a su favor se vuelve imparable”. 
                  </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
