import React from 'react'
import { Container } from '@material-ui/core'
import mainImg from "../../../../src/assets/images/aws_blog/post01.png";
import arquitecturaImg from "../../../../src/assets/images/aws_blog/arquitectura.png";
import Grid from '@material-ui/core/Grid';
import { CabeceraPublicacion } from '../../../components/aws/casos_exito/CabeceraPublicacion';

export const CasoCloudSearch = () => {
    return (
        <div>
{/*             <Cabecera
                titulo={[
                    "AWS ", <label key="titulo_cultura" style={{ color: 'var(--rojo)' }}>Blog</label>
                ]}
                icono={aws_blog}
                descripcion="¡Hagamos historia juntos!"
            ></Cabecera> */}
            <div style={{ paddingTop: '60px', backgroundColor: "var(--plomo-oscuro)", paddingBottom:"30px" }}>
            <Container>
                <Grid container direction='row' justifyContent="center">
                    <Grid item sm={9} xs={12} style={{padding:"60px 30px"}}>
                        <CabeceraPublicacion
                            img={mainImg} 
                            titulo="La búsqueda rápida y AWS"
                            contenido="Miércoles, 22 de julio del 2020"
                        />
                        <div>
                            <p className="font-raleway" style={{ backgroundColor: '#232F3E', color: 'white', marginTop:"15px", padding:"5px" }}>Nuestro cliente</p>
                            <p>Nuestro cliente es una empresa del sector retail ubicado en Perú perteneciente a un reconocido grupo empresarial, con presencia
                            en varias regiones y con especial foco en el canal del e-commerce por el contexto actual.</p>

                            <p className="font-raleway" style={{ backgroundColor: '#232F3E', color: 'white', marginTop:"15px", padding:"5px" }}>La situación</p>
                            <p>Debido al incremento de las ventas por el canal de e-commerce, se presenta una mayor demanda de búsqueda de artículos y un factor
                            importante para el cierre de la venta es la búsqueda de los artículos.</p>

                            <p className="font-raleway" style={{ backgroundColor: '#232F3E', color: 'white', marginTop:"15px", padding:"5px" }}>La solución</p>
                            <p>Esta búsqueda debe ser rápida y fiable, un enfoque para lograr este objetivo es a través del servicio CloudSearch como componente
                            core de la solución.</p>

                            <p className="font-raleway" style={{ backgroundColor: '#232F3E', color: 'white', marginTop:"15px", padding:"5px" }}>La arquitectura</p>
                            <p>Para el diseño de la solución se planteó una arquitectura híbrida y desacoplada, esta arquitectura debería ser agnóstica a algún
                            problema en el ambiente on-premises. También debería ser automatizado todo el proceso y gestionado por el equipo de Manantial,
                            para lograr este objetivo planteado se usó el servicio de S3 como componente de repositorio inicial de la información proveniente
                            de ambiente on-premises, luego mediante los servicios de Lambda se traslada hacia Dynamo desde donde se envía la información
                            a los dominios de CloudSearch, una vez finalizado el proceso se informa mediante SES el resultado del proceso de carga.</p>
                            <p>Durante la operación se monitorea la disponibilidad del dominio de CloudSearch y el estado de los servicios mediante el Cloudwatch.</p>

                            <img alt='Arquitectura Cloud' src={arquitecturaImg} style={{ width: '100%', marginTop:"15px",marginBottom:"15px" }}></img>

                            <p>Un aspecto importante del éxito de la solución es entender la necesidad del cliente, sentir cuál es impacto de su necesidad,
                            en Manantial nos comprometemos con la solución de esa necesidad en términos de costo, tiempo de implementación y su efectividad.</p>

                        </div>
                    </Grid>
                </Grid>
            </Container>
            </div>
        </div >
    )
}
