import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Button, CardActions } from "@material-ui/core";
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    minHeight: 250,
    backgroundColor: "#232F3E !important",
  },
});

export default function CardSlider(props) {
  const classes = useStyles();

  const icono = props.icono.default
  const titulo = props.titulo
  const descripcion = props.descripcion
  const url = props.url

  return (
    <Card className={classes.root} style={{ marginTop: '80px' }}>
            <CardActionArea>
                <center>
                <img alt="icono" style={{paddingTop: '20px', margin: 'auto'}} src={icono} width="74" height="74"></img >
                </center>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2" style={{ textAlign: 'center', color: 'var(--rojo)' }}>
                        {titulo}
                    </Typography>
                    <Typography variant="body2" component="p" style={{ textAlign: 'center', color: '#ffffff' }}>
                      {descripcion}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Link to={url} target="blank" style={{textDecoration: 'none'}}>
                    <Button size="small" color="primary" style={{marginTop: '28px', marginBottom: '8px', backgroundColor: 'var(--rojo)', color: 'white'}}>
                        Explorar
                    </Button>
                </Link>
            </CardActions>
        </Card>
  );
}
