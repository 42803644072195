import React from 'react';
import { Container, Typography } from '@material-ui/core';
import Cabecera from "../cabecera/Cabecera";
import comunicate from '../../assets/images/contacto/comunicate.svg';
import FormularioContacto from './FomularioContacto';
import Grid from '@material-ui/core/Grid';

export const Formulario = () => {
    return (
        <>
            <Cabecera
                titulo={[
                    <label key="titulo_servicios" style={{ color: 'var(--rojo)' }}>Contáctate </label>, " con nosotros"
                ]}
                icono={comunicate}
                descripcion="No nos detenemos, hasta que lo bueno sea mejor y lo mejor sea excelente"
            ></Cabecera>

            <div style={{ paddingTop: '80px', backgroundColor: "var(--plomo-claro)", paddingBottom:'80px' }}>
                <Container>
                    <Typography className="font-sora" variant="h5" component="h2" style={{
                        textAlign: 'center',
                        paddingBottom: '80px'
                    }} >Para obtener mayor <label style={{ color: 'var(--rojo)' }}> información </label> sobre  nuestros servicios , por favor
                    <br></br>
                     contacte con nosotros mediante el siguiente <label style={{ color: 'var(--rojo)' }}> formulario.</label>
                    </Typography>
                </Container>

                <Container className="container-contacto" style={{display: 'flex', justifyContent: 'center'}}>
                        <Grid item xs={12} style={{ backgroundColor: 'var(--azul)', maxWidth: '520px'}} className="componente-formulario">
                            <FormularioContacto></FormularioContacto>
                        </Grid>                    
                </Container>

            </div>
        </>
    )
}