import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import logoDemo from "../../assets/images/logo/logo-demos.svg";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function Demo() {
  const classes = useStyles();
  const [open, setOpenDemo] = React.useState(false);
  const anchorRef = React.useRef(null);

  function handleToggleDemo () {
    setOpenDemo((openvalueDemo) => !openvalueDemo);
  };

  function handleCloseDemo (event) {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }

    setOpenDemo(false);
  };

  function handleListKeyDownDemo(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenDemo(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div
        style={{
          backgroundColor: "#C74634",
          borderRadius: "50%",
          height: "38px",
          width: "38px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "8px",
          fontFamily: "Raleway",
        }}
      >
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggleDemo}
          style={{ color: "white" }}
        >
          <img alt="logo Manantial"
            src={logoDemo}
            style={{
              height: "33px",
            }}
          ></img>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseDemo}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDownDemo}
                  >
                    {/* <Link to="/demo/nuestras-demos" style={{ textDecoration: "none", color: "black" }}>
                                            <MenuItem onClick={handleCloseDemo}>Nuestras Demos</MenuItem>
                                        </Link> */}
                    <Link
                      to="/demo/nuestras-demos/streaming"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem onClick={handleCloseDemo}>Streaming</MenuItem>
                    </Link>
                    <Link
                      to="/demo/nuestras-demos/reconocimiento-facial"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem onClick={handleCloseDemo}>
                        Reconocimiento facial
                      </MenuItem>
                    </Link>
                    <Link
                      to="/demo/nuestras-demos/consola-aws"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem onClick={handleCloseDemo}>
                        Consola de AWS
                      </MenuItem>
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
