import React from "react"
import { Container, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import impacto from "../../assets/images/nosotros_cultura/impacto.svg"

export default () => {
	return (
		<div style={{ paddingTop: "40px", paddingBottom: "40px", backgroundColor: "var(--azul)" }}>
			<Container>

				<Grid container direction="row">

		            <Grid item xs={12} style={{ padding: "20px", textAlign:"center" }}>
		                <Typography className="font-raleway" variant='h5'>
			            	<label style={{ color: 'white' }}>
			            			Mediante nuestra cultura buscamos <label style={{ color: 'var(--rojo)' }}>
			            				impactar en las organizaciones
			            			</label> en las que participamos, buscando que este impacto sea positivo desde nuestras <label style={{ color: 'var(--rojo)' }}>
			            			soluciones TI
			            			</label>, pero también desde nuestra cultura y valores.
			            	</label>
			             </Typography>
		            </Grid>

		            <Grid item xs={12} style={{ padding: "20px", textAlign: "center" }}>
		               <img src={ impacto } alt="impacto"></img>
		            </Grid>

	            </Grid>

			</Container>
		</div>
	)
}