import { Grid, Container, Typography } from "@material-ui/core";
import React from "react";
import { Beneficios } from "./Beneficios";
import { MotorDataBase } from "./MotorDataBase";

export const InformacionRDS = () => {
  return (
    <>
      <div
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
          backgroundColor: "var(--plomo-mas-oscuro)",
        }}
      >
        <Container>
          <Grid container justifyContent="space-between" spacing={4}>
            <Grid item md={6}>
              <div style={{ padding: "0px 50px" }}>
                <Typography
                  className="font-sora-bold"
                  style={{
                    color: "var(--rojo)",
                    marginBottom: "20px",
                    fontDisplay:"auto",
                  }}
                  variant="h5"
                >
                  Amazon Relational Database Service (Amazon RDS)
                </Typography>
                <Typography
                  className="font-raleway"
                  style={{ fontDisplay:"auto", color: "FFFFF", fontWeight: 550 }}
                  variant="h6"
                >
                  Capacidad rentable, escalable, automatiza las arduas tareas
                  administrativas, como el aprovisionamiento de hardware, la
                  configuración de bases de datos, la implementación de parches
                  y la creación de copias de seguridad.
                </Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <div style={{ padding: "0px 50px" }}>
                <iframe
                  width="560"
                  height="315"
                  src="https://youtube.com/embed/jW84JhqNWUY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ maxWidth: "100%", minHeight: "315px" }}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <MotorDataBase />
      <Beneficios />
      {/* <div style={{ marginTop: "50px", marginBottom: "50px" }}>
        <Typography
          className="font-sora"
          style={{ textAlign: "center", fontWeight: "bold", fontSize: "24px" }}
        >
          ¿ Quieres aprender a usar AWS RDS ?
        </Typography>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactPlayer
            controls
            url="https://web-manantial.s3.amazonaws.com/experimentar/consola/Video_manantial.mp4"
          />
        </div>
      </div>
      <CasoExito /> */}
    </>
  );
};
