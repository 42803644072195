import React from "react";
import Cabecera from "../../../components/cabecera/Cabecera";
import { InformacionRDS } from "../../../components/aws/servicios/rds/InformacionRDS";
import rds from "../../../assets/images/servicios_aws/logo-rds.svg";

export const ServicioRDS = () => {
  return (
    <>
      <Cabecera
        titulo={[
          "Amazon ",
          <label key="titulo_lambda" style={{ color: "var(--rojo)" }}>
            RDS
          </label>,
        ]}
        icono={rds}
        descripcion="Configure, use y escale una base de datos relacional en la nube"
      />
      <InformacionRDS/>
    </>
  );
};
