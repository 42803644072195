import React from 'react';
import useStyles from './UseStyles';
import { Link } from 'react-router-dom';
import { Container } from '@material-ui/core';
import aws from '../../assets/images/slider/aws2.png';
import oracle from '../../assets/images/slider/oracle.png';
import google from '../../assets/images/slider/google-partner.png';
import quest from '../../assets/images/slider/quest.png';
import veeam from '../../assets/images/slider/veeam_partner.png';
import fortinet from '../../assets/images/slider/fortinet_partner.png';
import odoo from '../../assets/images/slider/odoo-partner.png';
import paloalto from '../../assets/images/slider/paloalto-partner.png';
import './carousel.css';
 
export default () => {
  const classes = useStyles()
  return (
    <div>
      <div style={{ height: '100vh' }}>
        <div className='slide-container' style={{ height: '78%', width: '100wh', display: 'grid' }}>
          <div className='slide-container' style={{ height: '80%', width: '100wh', display: 'grid' }}>
            <div style={{backgroundColor:"#232F3E"}}
              params={{
                interactivity: {
                  events: {
                    onHover: {
                      enable: true,
                      mode: 'attract'
                    }
                  }
                },
                particles: {
                  number: {
                    value: 100
                  },
                  size: {
                    value: 3
                  },
                  color: {
                    value: "#ffffff"
                  },
                  links: {
                    enable: true,
                    distance: 100,
                    color: "#ffffff",
                    opacity: 0.1,
                    width: 2
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 2,
                    straight: false,
                  },
                  opacity: {
                    value: 0.4,
                  },
                  shape: {
                    type: ["edge","circle"]
                  }
                },
                fpsLimit: 40
              }}
            />
          </div>
          <div className="box-welcome text-center pt-2 pb-3" style={{ display: 'flex', position: 'relative', height: '80vh', backgroundColor:"#232F3E"}}>
            <Container>
              <div className={classes.centerContainer}>
                <p className={classes.title}> Somos partner de: </p>
                <div>
                  <img className={classes.awsPartnerLogo}  src={aws} alt="aws" />
                  <img className={classes.googlePartnerLogo} style={{margin:' 15px 20px'}} src={google} alt="google" />
                  <img className={classes.oraclePartnerLogo}  src={oracle} alt="oracle" />
                </div>
 
                <div className={classes.flexData}>
                  <img className={classes.veeamPartnerLogo} src={veeam} alt="veeam" style={{margin:'20px 0px', display: 'flex', justifyContent:'center', alignItems:'center'}}/>  
                  <img className={classes.fortinetPartnerLogo} src={fortinet} alt="fortinet" />
                  <img className={classes.paloaltoPartnerLogo}  src={paloalto} alt="paloalto" />
                  <img className={classes.questPartnerLogo} style={{margin:' 15px 16px'}} src={quest} alt="quest"/>  
                  <img className={classes.odooPartnerLogo} style={{margin:' 15px 0px'}} src={odoo} alt="odoo" />
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div className="box-welcome text-center pt-2 pb-3" style={{ backgroundColor: "var(--plomo-oscuro)", display: 'block', height: '15%', position: 'relative' }}>
          <span className="welcome-text font-weight-bold" style={{fontFamily: 'Sora'}}>Bienvenidos al <label style={{ color: "var(--rojo)" }}>mundo Cloud</label> de Manantial</span>
        </div>
        <Link className="container-arrow" style={{ backgroundColor: '#E6E9E9', height: '15%', alignItems: 'start' }} to='/servicios'>
          <div className="chevron"></div>
          <div className="chevron"></div>
          <div className="chevron" id='servicios'></div>
        </Link>
      </div>
  </div>
  );
}