import React, {useState} from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import "./experiencia.css";
import { VIDEOS } from "./data";
import { Typography } from '@material-ui/core';



const NextArrow = ({ onClick }) => {
    return (
        <div className='nextArrow' onClick={onClick}>
            <BsChevronRight className="color" />
        </div>
    );
};

const PrevArrow = ({ onClick }) => {
    return (
        <div className='prevArrow' onClick={onClick}>
            <BsChevronLeft className="color" />
        </div>
    );
};

const ImageSlider = ({ images, slidesToShow = 3 }) => {
    // 3.
    const [imageIndex, setImageIndex] = useState(0);

    // 4.
    const settings = {
        centerMode: true,
        infinite: true,
        dots: false,
        speed: 300,
        slidesToShow: slidesToShow,
        centerPadding: "0",
        swipeToSlide: true,
        focusOnSelect: true,
        nextArrow: <NextArrow onClick />,
        prevArrow: <PrevArrow onClick />,
        beforeChange: (current, next) => setImageIndex(next),
        responsive: [
            {
                breakpoint: 1490,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // 5.
    const templateImages = images.map((image, idx) => {
        return (
            <div
                className={idx === imageIndex ? "activeSlide" : "slide"}
                key={image.id}
            >
                <div className="slideWrapper">
                    {image.code ? image.code : <img src={image.src} alt={image.alt} />}
                </div>
            </div>
        );
    });

    return <Slider {...settings}>{templateImages}</Slider>;
};


export default () => {
    return (
        <div className='App' style={{backgroundColor: 'white', paddingBottom: '80px' }}>
            <Typography className='title' variant='h5'> <label style={{ fontDisplay:"auto", color: 'var(--rojo)' }}>Experiencia </label> y genialidad <label style={{ color: 'var(--rojo)' }}>en acción</label> </Typography>
            <div className='container'>
                <ImageSlider style={{ paddingBottom: '2.5rem' }} images={VIDEOS} />
            </div>
        </div>
    );
}
