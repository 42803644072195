import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";

export const Demostraciones = () => {
  return (
    <div
      style={{
        paddingTop: "50px",
        paddingBottom: "50px",
        backgroundColor: "#E6E9E9",
      }}
    >
      <Container>
        <Typography
          className="font-sora"
          style={{
            color: "var(--rojo)",
            textAlign: "center",
            fontSize: "24px",
            marginBottom: "50px",
            fontWeight: "bold",
            fontDisplay:"auto",
          }}
        >
          Demostraciones
        </Typography>
        <div>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "0px 50px" }}>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/s_queaqyzHs"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ maxWidth: "100%", minHeight: "315px" }}
                  />
                </div>
                <Typography style={{ fontDisplay:"auto", marginTop: "20px", fontWeight: "bold" }}>
                  ¿Cómo activo las conexiones rápidas en Amazon Connect?
                </Typography>
              </div>
            </Grid>

            <Grid item md={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "0px 50px" }}>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/BVa6QZyAVsA"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ maxWidth: "100%", minHeight: "315px" }}
                  />
                </div>
                <Typography style={{ marginTop: "20px", fontWeight: "bold" }}>
                  Entrenamiento de agentes para el panel de control de contacto
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};
