import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button, CardActions } from '@material-ui/core';

import UseStyles from './UseStyless';

export default function CardPageNotFound(props) {
    const classes = UseStyles();
    const title = props.title
    const description = props.description
    const url = props.url

    return (
        <Card className={classes.root}>
            <CardActionArea style={{height: '200px'}}>
                <Typography gutterBottom variant="h6" component="h2" style={{ textAlign: 'center', color: 'var(--rojo)', padding: '10px 16px 0px 16px', height: '60px'}}>
                    {title}
                </Typography>
                <CardContent>
                    <Typography variant="body2" component="p" style={{ textAlign: 'center', color: 'white', paddingTop: '20px'}}>
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ maxHeight: '40px', alignItems: 'flex-end', justifyContent: 'center', paddingBottom: '20px'}}>
                <Button className={classes.button} size="small" color="primary" href={url} target="blank_">
                    Ver Más
                </Button>
            </CardActions>
        </Card>
    );
}