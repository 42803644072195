import React from 'react';
import Consola from "./Consola";
import Facial from "./Facial";
import Streaming from "./Streaming";
import Repositorio from "./Repositorio";

  export const VIDEOS = [
    {
      id: 1,
      code: (
        <>
          <Facial></Facial>
        </>
      ),
    },
    {
      id: 2,
      code: (
        <>
          <Streaming></Streaming>
        </>
      ),
    },
    {
      id: 3,
      code: (
        <>
          <Consola></Consola>
        </>
      ),
    },
    {
      id: 4,
      code: (
        <>
          <Repositorio></Repositorio>
        </>
      ),
    },
  ]