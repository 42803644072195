import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";

export const Beneficios = () => {
  return (
    <div
      style={{
        padding: "50px 0px",
        backgroundColor: "#E6E9E9",
      }}
    >
      <Container>
        <Typography
          className="font-sora"
          style={{
            color: "var(--rojo)",
            textAlign: "center",
            fontSize: "24px",
            marginBottom: "50px",
            fontWeight: "bold",
            fontDisplay:"auto",
          }}
        >
          Beneficios
        </Typography>
        <Grid container justifyContent="space-between" spacing={4}>
          <Grid item md={4}>
            <Card
              style={{
                backgroundColor: "#232F3E",
              }}
            >
              <CardContent>
                <Typography
                  style={{
                    textAlign: "justify",
                    color: "#ffffff",
                  }}
                >
                  Con solo unos clics, puede configurar y hacer cambios en su
                  centro de contacto, para que los agentes puedan ayudar de
                  inmediato a los clientes.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card
              style={{
                backgroundColor: "#232F3E",
              }}
            >
              <CardContent>
                <Typography
                  style={{ fontDisplay:"auto",
                    textAlign: "justify",
                    color: "#ffffff",
                  }}
                >
                  Ahorre hasta un 80 por ciento en comparación con las
                  soluciones tradicionales de centros de contacto sin tarifas
                  mínimas, compromisos a largo plazo ni cargos de licencia por
                  adelantado.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card
              style={{
                backgroundColor: "#232F3E",
              }}
            >
              <CardContent>
                <Typography
                  style={{
                    textAlign: "justify",
                    color: "#ffffff",
                  }}
                >
                  Escale verticalmente o reduzca fácilmente para satisfacer la
                  demanda, con la flexibilidad de incorporar a decenas de miles
                  de agentes que trabajan desde cualquier lugar.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
