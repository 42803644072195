import { Container, Typography } from "@material-ui/core";
import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const UseCase = () => {
  return (
    <div
      style={{
        paddingTop: "50px",
        paddingBottom: "50px",
      }}
    >
      <Container>
        <Typography
          className="font-sora"
          style={{
            color: "var(--rojo)",
            textAlign: "center",
            fontSize: "24px",
            marginBottom: "50px",
            fontWeight: "bold",
            fontDisplay:"auto",
          }}
        >
          Casos de Uso
        </Typography>

        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              style={{
                fontSize: "17px",
                fontWeight: "bold",
                fontDisplay:"auto",
              }}
            >
              Proporcione un servicio al cliente omnicanal
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Cree experiencias de voz y de conversación interactivas omnicanal
              de alta calidad para ayudar a sus clientes desde cualquier lugar.
              Utilice una única interfaz de usuario intuitiva para el
              enrutamiento de contactos, las colas y el análisis.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              style={{
                fontSize: "17px",
                fontWeight: "bold",
                fontDisplay:"auto",
              }}
            >
              Utilice IA y ML incorporados para personalizar las interacciones
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Con inteligencia artificial (IA) y machine learning (ML)
              integrados, Amazon Connect facilita la automatización de
              interacciones, la comprensión del sentimiento de los clientes, la
              autenticación de las personas que llaman y la habilitación de
              capacidades como la respuesta de voz interactiva y los chatbots.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              style={{
                fontSize: "17px",
                fontWeight: "bold",
                fontDisplay:"auto",
              }}
            >
              Mejore la productividad de los agentes
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Capacite a sus agentes para que sean más proactivos y productivos.
              Descubra perfiles de clientes unificados y respuestas recomendadas
              en tiempo real, y monitoree las tareas de seguimiento para
              resolver rápidamente los problemas de los clientes.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </div>
  );
};
