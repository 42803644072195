import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import aurora from "../../../../assets/images/servicios_aws/rds/aurora.svg";
import mariaDB from "../../../../assets/images/servicios_aws/rds/mariaDB.svg";
import microsoftSQLServer from "../../../../assets/images/servicios_aws/rds/microsoftSQLServer.svg";
import mySQL from "../../../../assets/images/servicios_aws/rds/mySQL.svg";
import oracle from "../../../../assets/images/servicios_aws/rds/oracle.svg";
import postgreSQL from "../../../../assets/images/servicios_aws/rds/postgreSQL.svg";
import { Link } from "react-router-dom";


export const MotorDataBase = () => {
  return (
    <div style={{ marginTop: "50px", marginBottom: "50px" }}>
      <Container>
        <Typography
          className="font-sora"
          style={{
            color: "var(--rojo)",
            textAlign: "center",
            fontSize: "24px",
            marginBottom: "50px",
            fontDisplay:"auto",
          }}
        >
          Elige tu motor de bases de datos
        </Typography>
        <Grid container spacing={4} justifyContent="center" align="center">
          <Grid item md={4}>
            <Link href="https://aws.amazon.com/es/rds/aurora/" target="blank">
              <img alt="Amazon Aurora" src={aurora} />
            </Link>
          </Grid>
          <Grid item md={4}>
            <Link to="https://aws.amazon.com/es/rds/postgresql/" target="blank">
              <img alt="Amazon RDS PostgreSQL" src={postgreSQL} />
            </Link>
          </Grid>
          <Grid item md={4}>
            <Link to="https://aws.amazon.com/es/rds/mysql/" target="blank">
              <img alt="Amazon RDS MySQL" src={mySQL} />
            </Link>
          </Grid>
          <Grid item md={4}>
            <Link to="https://aws.amazon.com/es/rds/mariadb/" target="blank">
              <img alt="Amazon RDS MariaDB" src={mariaDB} />
            </Link>
          </Grid>
          <Grid item md={4}>
            <Link to="https://aws.amazon.com/es/rds/oracle/" target="blank">
              <img alt="Amazon RDS Oracle" src={oracle} />
            </Link>
          </Grid>
          <Grid item md={4}>
            <Link to="https://aws.amazon.com/es/rds/sqlserver/" target="blank">
              <img alt="Amazon RDS SQL Server" src={microsoftSQLServer} />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
