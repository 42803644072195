import { Container, Grid, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import Congratulation from '../../../assets/images/proximos_pasos/Congratulation.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '80px'
        }
    },
    grid: {
        [theme.breakpoints.down(465)]: {
            margin: 'auto'
        }
    }
}))

export const Contenido = () => {
    const classes = useStyles();
    return (
        <div style={{ backgroundColor: 'var(--plomo-claro)', paddingTop: '50px', paddingBottom: '50px' }}>
            <Container style={{}}>
                <div className={classes.root}>
                    <Typography className="font-sora" style={{ fontDisplay:"auto",fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
                        <span style={{fontDisplay:"auto", color: 'var(--rojo)' }}>Corto</span> Plazo
                </Typography>
                    <Typography>
                        Obtener el programa de Service Delivery Program (SDP) en Relational Database Service (RDS)
                        <br />y Web Application Firewall (WAF).
                </Typography>
                </div>
                <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="center">
                    <Grid>
                        <div style={{ marginBottom: '20px' }}>
                            <Typography className="font-sora" style={{fontDisplay:"auto", fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
                                <span style={{fontDisplay:"auto", color: 'var(--rojo)' }}>Mediano</span> Plazo
                        </Typography>
                            <Typography>
                                Ser Partner de nivel Advanced
                        </Typography>
                        </div>
                    </Grid>
                    <Grid className={classes.grid}>
                        <div style={{ marginTop: '50px' }}>
                            <img alt='Congratulation' src={Congratulation} />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
