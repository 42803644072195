import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
  Button,
  CardMedia,
} from "@material-ui/core";
import scaling from "../../../assets/images/casos_de_exito/scaling.png";
import React from "react";
import {Link} from "react-router-dom";


export const CardCasoScaling = (props) => {
  return (
    <Card
      style={{
        backgroundColor: "#232F3E",
        margin: "auto",
        minWidth: "200px",
        maxWidth: "300px",
      }}
    >
      <CardActionArea>
        <CardMedia
          style={{ height: "180px" }}
          image={scaling}
          title="La búsqueda rápida y AWS"
        />
        <CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{fontDisplay:"auto", color: "white" }}
          >
            Nuestro cliente, es un operador líder de las franquicias reconocidas
            a nivel mundial actualmente manejan 12 unidades de negocios.
            <br />
            <br />
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        style={{
          maxHeight: "40px",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <Link
            to="/aws/casos-exito/escalabilidad-negocio"
            style={{
              backgroundColor: "var(--rojo)",
              color: "white",
              padding: "4px 8px",
              borderRadius: "2px",
            }}
            size="small"
            color="primary"
          >
          <Button
            style={{
              backgroundColor: "var(--rojo)",
              color: "white",
              padding: "4px 8px",
              borderRadius: "2px",
            }}
            >
          Ver Más
        </Button>
        </Link>
      </CardActions>
    </Card>
  );
};
