import React from "react";
import SliderRDS from "./slider-rds/SliderRDS";

export const Beneficios = () => {
  return (
    <div
      style={{
        backgroundColor: "#E6E9E9",
        paddingTop: "50px",
        paddingBottom: "50px",
      }}
    >
      <SliderRDS />
    </div>
  );
};
