import { Card, CardContent, Typography } from '@material-ui/core'
import img from '../../assets/images/nosotros_equipo/img.png'
import React from 'react'

export const CardEquipo = (props) => {
    const cardData = props.data
    return (
        <Card style={{ backgroundColor: "#232F3E", minWidth: '180px', maxWidth: "280px", margin:"auto" }}>
            <CardContent
                style={{
                    backgroundColor: "white",
                    borderRadius: "0 0 40px 40px",
                    height: "225px",
                    minWidth: '180px', maxWidth: "280px"

                }}>
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <img src={cardData.foto} width="100px" height="100px" className="rounded-circle" alt="foto"></img>
                    <Typography className="font-sora" style={{ fontDisplay:"auto", color: '#232F3E', fontWeight: 'bold', marginTop: '20px' }}>
                        {cardData.puesto} 
                    </Typography>
                    <Typography className="font-raleway" style={{ fontDisplay:"auto", color: 'var(--rojo)', fontWeight: 'bold', marginTop: '20px' }}>
                        {cardData.nombres} {cardData.apellidos}
                    </Typography>
                </div>
            </CardContent>
            <CardContent
                style={{
                    backgroundColor: "#232F3E",
                    height: "50px",
                    minWidth: '180px', maxWidth: "280px"
                }}>
                <Typography className="font-raleway" style={{ textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                    {cardData.frase}
              </Typography>
            </CardContent>
        </Card>
    )
}
