import { Container, Typography } from "@material-ui/core";
import React from "react";
import funcionamiento_connect from "../../../../assets/images/servicios_aws/connect/funcionamiento_connect.png";

export const Funcionamiento = () => {
  return (
    <div style={{ marginTop: "50px", marginBottom: "50px" }}>
      <Container>
        <Typography
          className="font-sora"
          style={{
            color: "var(--rojo)",
            textAlign: "center",
            fontSize: "24px",
            marginBottom: "50px",
            fontWeight: "bold",
          }}
        >
          Funcionamiento
        </Typography>
        <Typography style={{fontDisplay:"auto", textAlign: "center" }}>
          Con Amazon Connect puede configurar un centro de contacto en minutos
          que puede escalarse para admitir millones de clientes.
        </Typography>
        <img
          src={funcionamiento_connect}
          alt="Amazon Connect"
          style={{
            display: "block",
            width: "100%",
            maxWidth: "1000px",
            margin: "40px auto",
          }}
        />
      </Container>
    </div>
  );
};
