import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { DataBase } from "./DataBase";
import { Security } from "./Security";
import { Contained } from "./Contained";
import { Business } from "./Business";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  };

  function handleChangeIndex(index) {
    setValue(index);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{ style: { backgroundColor: "var(--rojo)" } }}
        aria-label="full width tabs example"
        centered
      >
        <Tab label="Base de Datos" {...a11yProps(0)} />
        <Tab label="Seguridad" {...a11yProps(1)} />
        <Tab label="Contenedores" {...a11yProps(2)} />
        <Tab label="Empresa" {...a11yProps(2)} />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <DataBase />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Security />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Contained />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Business />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
