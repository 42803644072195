import { Container, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '50px'
    },
    heading: {
        fontSize: '17px',
        fontWeight: 'bold',
    },
}));

export const InformacionManantial = () => {
    const classes = useStyles();
    return (
        <Container style={{ marginTop: '50px', marginBottom: '80px'}}>
            {/* <div>
                <hr style={{ color: 'var(--rojo)', height: '5px' }} />
            </div> */}
            <Typography className="font-sora" style={{fontDisplay:"auto", fontWeight: 'bold', fontSize: '30px', color: 'var(--rojo)'}}>
                Conócenos
            </Typography>
            <div className={classes.root}>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>¿Quienes somos?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Somos Manantial y nacimos para darles oportunidades a las personas que están
                            comprometidas con su futuro y el futuro de la sociedad, somos conscientes que necesitamos
                            de personas comprometidas dispuestas a asumir el reto como personas y profesionales.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>¿Qué hacemos?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            En Manantial promovemos los valores basados en nuestra “Cooltura”, una cultura definida y
                            abrazada por cada uno de los integrantes de Manantial quienes definimos nuestra identidad en
                            conjunto escuchando la voz de todos, porque ese es el único modo de sentirse“parte de”. Esta es nuestra empresa, es
                            nuestra familia y estamos aqui para apoyarlos !
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ backgroundColor: '#E5E5E5', marginBottom: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>¿Cómo lo hacemos?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Fluímos contigo... Esta frase refleja nuestro sentido de existencia como empresa:
                            “Estar siempre al lado de nuestros colaboradores y socios de negocio” para apoyarlos
                            en sus necesidades y cumplir con sus objetivos.Este acompañamiento lo realizamos mediante
                            la empatía hacia ellos, porque si nos ponemos en sus “zapatos” podemos identificar de modo más
                            rápido sus necesidades para apoyarlos con la solución.
                        </p>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Container>
    )
}
