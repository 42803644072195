import { Container, Typography } from '@material-ui/core'
import React from 'react'
import Grid from '@material-ui/core/Grid';
import img from '../../../assets/images/aws_blog/post01.png'
import { Link } from "react-router-dom";
import { CabeceraPublicacion } from "./CabeceraPublicacion"


export const RecientePublicacion = () => {
    return (
        <div style={{ paddingTop: '60px', backgroundColor: "var(--plomo-oscuro)", paddingBottom: "10px" }}>
            <Container>
                <Grid container direction="row" justifyContent="center">
                    <Grid item sm={9} xs={12} style={{ padding: "10px 30px" }}>
                        <CabeceraPublicacion
                            img={img}
                            titulo="La búsqueda rápida y AWS"
                            contenido="Debido al incremento de las ventas por el canal de e-commerce, se presenta una mayor demanda de búsqueda de artículos y un factor importante para el cierre de la venta es la búsqueda de los artículos."
                        />
                        <center>
                            <Link to="/aws/casos-exito/la-busqueda-rapida-y-aws" style={{fontDisplay:"auto", textDecoration: "none" }}>
                                <Typography variant='h6' className="font-raleway" style={{fontDisplay:"auto", fontWeight: "bold" }}>
                                    Leer más...
                                </Typography>
                            </Link>
                        </center>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
