import React from "react";
import Cabecera from "../../components/cabecera/Cabecera";
import nosotros_cultura from "../../assets/images/nosotros_cultura/nosotros_cultura.svg";
import Proposito from "../../components/nosotros_cultura/Proposito";
import Impacto from "../../components/nosotros_cultura/Impacto";
import Valores from "../../components/nosotros_cultura/Valores";
import Mercado from "../../components/nosotros_cultura/Mercado";

export const Cultura = () => {
  return (
    <div>
      <Cabecera
        titulo={[
          <label key="titulo_cultura" style={{ color: "var(--rojo)" }}>
            Cooltura
          </label>,
          " Manantial",
        ]}
        icono={nosotros_cultura}
        descripcion="Ir juntos es comenzar, mantenerse juntos es progresar, trabajar juntos es triunfar!!!"
      ></Cabecera>
      <Proposito></Proposito>
      <Impacto></Impacto>
      <Valores></Valores>
      <Mercado></Mercado>
    </div>
  );
};
