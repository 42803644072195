import React from "react";
import Cabecera from "../../../components/cabecera/Cabecera";
import connect from "../../../assets/images/servicios_aws/connect.png";
import { InformacionConnect } from "../../../components/aws/servicios/connect/InformacionConnect";

export const ServicioConnect = () => {
  return (
    <>
      <Cabecera
        titulo={[
          "Amazon ",
          <label key="titulo_lambda" style={{ color: "var(--rojo)" }}>
            Connect
          </label>,
        ]}
        icono={connect}
        descripcion="Centro de contacto omnicanal en la nube fácil de usar"
      />
      <InformacionConnect />
    </>
  );
};
