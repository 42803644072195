import React from 'react'
import Cabecera from "../../components/cabecera/Cabecera"
import CasoExito from '../../../src/assets/images/aws_blog/CasoExito.svg'
import { RecientePublicacion } from "../../components/aws/casos_exito/RecientePublicacion"
import { Publicaciones } from "../../components/aws/casos_exito/Publicaciones"

export const CasosExito = () => {
    return (
        <div>
            <Cabecera
                titulo={[
                    <label key="titulo_cultura" style={{ color: 'var(--rojo)' }}>Casos</label>, " de éxito"
                ]}
                icono={CasoExito}
                descripcion="¡Hagamos historia juntos!"
            ></Cabecera>
            <RecientePublicacion/>
            <Publicaciones/>
        </div >
    )
}
