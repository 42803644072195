import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import managed_service from '../../assets/images/servicios/managed_service.svg'
import check from '../../assets/images/servicios_manantial/check.svg'

import UseStyles from './UseStyless';

export default function Gestionado_Servicios() {
    const classes = UseStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea style={{ height: "100%" }}>
                <img alt="Servicios Gestionados" className={classes.rooter} src={managed_service}></img>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" style={{ textAlign: 'center', color: 'var(--rojo)' }}>
                        Servicios Gestionados
                    </Typography>
                    <Typography variant="body2" component="p" style={{ textAlign: 'center' }}>
                        Brindamos la operación de TI como servicios, enfocados en su visión y necesidades de negocios.
                    </Typography>
                    <br></br>
                    <div style={{ display: 'flex', paddingLeft: '20px', paddingBottom: '10px' }}>
                        <img alt="check" src={check}></img>
                        <Typography style={{ paddingLeft: '10px' }}>
                            Gestión de Base de datos.
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', paddingLeft: '20px', paddingBottom: '10px' }}>
                        <img alt="check" src={check}></img>
                        <Typography style={{ paddingLeft: '10px' }}>
                            Gestión de servidores de aplicaciones.
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', paddingLeft: '20px', paddingBottom: '10px' }}>
                        <img alt="check" src={check}></img>
                        <Typography style={{ paddingLeft: '10px' }}>
                            Gestión de sistemas operativos.
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', paddingLeft: '20px', paddingBottom: '10px' }}>
                        <img alt="check" src={check}></img>
                        <Typography style={{ paddingLeft: '10px' }}>
                        Gestión de Virtualización.
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', paddingLeft: '20px', paddingBottom: '10px' }}>
                        <img alt="check" src={check}></img>
                        <Typography style={{ paddingLeft: '10px' }}>
                        Gestión de Cloud.
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', paddingLeft: '20px', paddingBottom: '10px' }}>
                        <img alt="check" src={check}></img>
                        <Typography style={{ paddingLeft: '10px' }}>
                        Gestión de Backups.
                        </Typography>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}