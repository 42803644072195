import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
      width: '100wh',
      flexGrow: 1
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    img: {
      height: 400,
      width: '100%',
      display: 'block',
      overflow: 'hidden',
      objectFit: 'cover',
      resize: 'none',
      opacity: '0.92'
    },
    containerOnSlide: {
      backgroundColor: 'red'
    },
    centerContainer: {
      color: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center'
    },
    flexData: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: "center",
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between !important',
        minWidth: 632
      }
    },
    title: {
      marginBottom: '20px',
      fontSize: '24px',
      fontFamily: 'Raleway',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      }
    },
    iconCloud: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        display: 'block',
        maxHeight: '60px',
        width: 'auto',
      }
    },
    flexData2: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: "center"
      
    },
    awsPartnerLogo: {
      width: 'auto',
      height: '80px',
      margin: "20px",
      [theme.breakpoints.down('sm')]: {
        height: '40px',
        margin: "10px"
      }
    },
    fortinetPartnerLogo: {
      width: 'auto',
      height: '70px',
      [theme.breakpoints.down('sm')]: {
        height: '45px',
      }
    },
    oraclePartnerLogo: {
      width: 'auto',
      height: '87px',
      [theme.breakpoints.down('sm')]: {
        height: '55px',
        
      }
    },
    odooPartnerLogo: {
      width: 'auto',
      height: '45px',
      [theme.breakpoints.down('sm')]: {
        height: '38px',
        
      }
    },
    googlePartnerLogo: {
      width: 'auto',
      height: '60px',

      [theme.breakpoints.down('sm')]: {
        height: '40px',
        marginBottom: "20px",
      }
    },
    questPartnerLogo: {
      width: 'auto',
      height: '65px',
      [theme.breakpoints.down('sm')]: {
        height: '40px',
      }
    },
    veeamPartnerLogo: {
      width: 'auto',
      height: '70px',
      [theme.breakpoints.down('sm')]: {
        height: '40px',
     
      }
    },
    paloaltoPartnerLogo: {
      width: 'auto',
      height: '45px',
      [theme.breakpoints.down('sm')]: {
        height: '35px',
      
      }
    },
  }));