import React from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    btn: {
        transition: 'width 20s',
        '&:hover': {
            fontSize: '18px',
            color: '#C74634 !important',
            fontWeight: '600'
        }
    }
}));

export default function Servicios() {
    const classes = useStyles();
    const [open, setOpenServicios] = React.useState(false);
    const anchorRef = React.useRef(null);

    function handleToggleServicios () {
        setOpenServicios((openvalueServicios) => !openvalueServicios);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleServicios}
                    style={{ color: 'white', minWidth: '100px', fontFamily: 'Raleway', textTransform: 'capitalize' }}
                >
                    Servicios
                </Button>
            </div>
        </div>
    );
}