import React from "react";
import Aws from "./Aws";
import Nosotros from "./Nosotros";
import Clientes from "./Clientes";
import Servicios from "./Servicios";
import Oracle from "./Oracle";
import Contacto from "./Contacto";
import Demo from "./Demo";
import { AppBar, Container } from "@material-ui/core";
import logo from "../../assets/images/logo/logo-rojo-blanco.png";
import { makeStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import menu from "../../assets/images/menu/menu.svg";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Link } from "react-router-dom";

import "./navbar.css";
import Veeam from "./Veeam";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "flex",
    alignItems: "center",
    height: 64,
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    height: 64,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logo: {
    height: "48px",
    marginLeft: "16px",
  },
  listItem: {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionDetails: {
    display: "flex",
    flexWrap: "wrap",
  },
  menuContainer: {
    height: 64,
  },
  drawerContainer: {},
}));

export default () => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div className="list-container" style={{ width: "260px" }}>
      <List style={{ backgroundColor: "#232f3e" }}>
        <Link
          to="/"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
          style={{ textDecoration: "none" }}
        >
          <img alt="logo Manantial" src={logo} className={classes.logo}></img>
        </Link>
      </List>
      <Divider />

      <>
        <Accordion>
          <AccordionSummary
            style={{ minWidth: "200px !important" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}> Nosotros</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/nosotros/cultura"
                style={{ textDecoration: "none", color: "black" }}
              >
                Nuestra cultura
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/nosotros/equipo"
                style={{ textDecoration: "none", color: "black" }}
              >
                Nuestro equipo
              </Link>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </>
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Servicios</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/servicios"
                style={{ textDecoration: "none", color: "black" }}
              >
                Nuestros servicios
              </Link>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </>
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Clientes</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/clientes/nuestros-clientes"
                style={{ textDecoration: "none", color: "black" }}
              >
                Nuestros clientes
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/clientes/testimonios"
                style={{ textDecoration: "none", color: "black" }}
              >
                Testimonios
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/clientes/nuestro-compromiso"
                style={{ textDecoration: "none", color: "black" }}
              >
                Nuestro compromiso
              </Link>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </>
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>AWS</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/aws/nuestro-compromiso"
                style={{ textDecoration: "none", color: "black" }}
              >
                Nuestro compromiso
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/aws/proximos-pasos"
                style={{ textDecoration: "none", color: "black" }}
              >
                Próximos pasos
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/aws/casos-exito"
                style={{ textDecoration: "none", color: "black" }}
              >
                Caso de éxito
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/aws/servicios"
                style={{ textDecoration: "none", color: "black" }}
              >
                Servicios de AWS
              </Link>
            </ListItem>
            <ListItem
              button
              role="presentation"
              className={classes.listItem}
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/aws/blog"
                style={{ color: "black", textDecoration: "none" }}
              >
                Blog
              </Link>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </>
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>ORACLE</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/oracle/nuestro-compromiso-con-oracle"
                style={{ textDecoration: "none", color: "black" }}
              >
                Nuestro compromiso
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/oracle/proximos-pasos"
                style={{ textDecoration: "none", color: "black" }}
              >
                Próximos pasos
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/oracle/caso-de-exito"
                style={{ textDecoration: "none", color: "black" }}
              >
                Caso de éxito
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/oracle/servicios-de-oracle"
                style={{ textDecoration: "none", color: "black" }}
              >
                Servicios de ORACLE
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/oracle/blog"
                style={{ textDecoration: "none", color: "black" }}
              >
                Blog
              </Link>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </>
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>VEEAM</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/veeam/nuestro-compromiso"
                style={{ textDecoration: "none", color: "black" }}
              >
                Nuestro compromiso
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/veeam/proximos-pasos"
                style={{ textDecoration: "none", color: "black" }}
              >
                Próximos pasos
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/veeam/casos-exito"
                style={{ textDecoration: "none", color: "black" }}
              >
                Caso de éxito
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/veeam/soluciones"
                style={{ textDecoration: "none", color: "black" }}
              >
                Soluciones de VEEAM
              </Link>
            </ListItem>
            <ListItem
              button
              role="presentation"
              className={classes.listItem}
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/veeam/blog"
                style={{ color: "black", textDecoration: "none" }}
              >
                Blog
              </Link>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </>
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Contacto</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/contacto/formulario"
                style={{ textDecoration: "none", color: "black" }}
              >
                Formulario
              </Link>
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <Link
                to="/contacto/empresa"
                style={{ textDecoration: "none", color: "black" }}
              >
                Información de la empresa
              </Link>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </>
    </div>
  );

  return (
    <>
      <AppBar style={{ backgroundColor: "#232f3e", height: "64px" }}>
        <Container className={classes.menuContainer}>
          <div className={classes.sectionDesktop}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <img alt="logo Manantial" src={logo} className={classes.logo}></img>
            </Link>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Nosotros></Nosotros>
              <Link to="/servicios" style={{ textDecoration: "none" }}>
                <Servicios></Servicios>
              </Link>
              <Clientes></Clientes>
              <Aws></Aws>
              <Oracle></Oracle>
              <Veeam></Veeam>
              <Contacto></Contacto>
              <Demo></Demo>
            </div>
          </div>

          <div className={classes.sectionMobile}>
            <div className={classes.drawerContainer}>
              <Button onClick={toggleDrawer("left", true)}>
                <img alt="Menu" src={menu} style={{ color: "white", height: "28px" }} />
              </Button>
              <Link to="/" style={{ textDecoration: "none" }}>
                <img alt="logo Manantial"
                  src={logo}
                  style={{
                    color: "white",
                    height: "28px",
                    paddingBottom: "2px",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </Link>
              <Drawer
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
                className="drawer-container"
              >
                {list("left")}
              </Drawer>
            </div>
            <Demo></Demo>
          </div>
        </Container>
      </AppBar>
    </>
  );
};
