import { Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import Estrategia from '../../assets/images/informacion_empresa/Estrategia.svg'
import Empresas from '../../assets/images/informacion_empresa/Empresas.svg'

const styles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            justifyContent: 'flex-start',
        }
    },
    containerChanges: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            justifyContent: 'flex-start',
        }
    },
    divContainer: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 20px',
        minHeight: '200px',
        maxWidth: '400px',
        [theme.breakpoints.down(341)]: {
            minHeight: '200px',
            maxWidth: '400px',
        },
        [theme.breakpoints.up('md')]: {
            height: '200px',
            width: '400px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '200px',
            width: '400px',
        }
    },
    divChanges: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 20px',
        minHeight: '200px',
        maxWidth: '400px',
        [theme.breakpoints.down(341)]: {
            minHeight: '200px',
            maxWidth: '400px',
            padding: '20px 20px'
        },
        [theme.breakpoints.up('md')]: {
            height: '200px',
            width: '400px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '200px',
            width: '400px',
        }
    },
    divContainerImg: {
        backgroundColor: '#232F3E',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '200px',
        maxWidth: '400px',
        [theme.breakpoints.down(341)]: {
            minHeight: '200px',
            maxWidth: '400px',
        },
        [theme.breakpoints.up('md')]: {
            height: '200px',
            width: '400px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '200px',
            width: '400px',
        },
    },
    divChangeImg: {
        backgroundColor: '#232F3E',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '200px',
        maxWidth: '400px',
        [theme.breakpoints.down(341)]: {
            minHeight: '200px',
            maxWidth: '400px',
        },
        [theme.breakpoints.up('md')]: {
            height: '200px',
            width: '400px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '200px',
            width: '400px',
        },
    }
}))
export const CardsInformacion = () => {
    const classes = styles();
    return (
        <div style={{ backgroundColor: '#F4F4F4', minHeight: '600px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.container}>
                    <div className={classes.divContainer}>
                        <Typography variant="h7" className="font-raleway" style={{ fontDisplay:"auto", textAlign: 'center' }}>
                            <label style={{ color: 'var(--rojo)', fontWeight: 'bold' }}>Somos Manantial</label> y nacimos para darles oportunidades a las personas que están
                            comprometidas con su futuro y el futuro de la sociedad, somos conscientes que necesitamos
                            de personas comprometidas dispuestas a asumir el reto como personas y profesionales.
                        </Typography>
                    </div>
                    <div className={classes.divContainerImg}>
                        <img alt="estrategia" src={Estrategia} />
                    </div>
                </div>
                <div className={classes.containerChanges}>
                    <div className={classes.divChangeImg}>
                        <img alt='empresas' src={Empresas} />
                    </div>
                    <div className={classes.divChanges}>
                        <Typography variant="h7" className="font-raleway" style={{ fontDisplay:"auto", textAlign: 'center' }}>
                            En <label style={{ color: 'var(--rojo)', fontWeight: 'bold' }}>Manantial</label> promovemos los valores basados en nuestra “Cooltura”, una cultura definida y
                            abrazada por cada uno de los integrantes de Manantial quienes definimos nuestra identidad en
                            conjunto escuchando la voz de todos, porque ese es el único modo de sentirse“parte de”. Esta es nuestra empresa, es
                            nuestra familia y estamos aqui para apoyarlos !
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}
