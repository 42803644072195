import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import Platform_Identity_and_Security_Management from "../../../assets/images/oracle_nuestro_compromiso/platform_indentity_and_security_management.svg";
import Infrastructure_Architect from "../../../assets/images/oracle_nuestro_compromiso/infraestructure_architect.svg";
import Platform_Application_Development from "../../../assets/images/oracle_nuestro_compromiso/platform_application_development.svg";
import Database_Services from "../../../assets/images/oracle_nuestro_compromiso/database_services.svg";
import Database_Machine_Learning from "../../../assets/images/oracle_nuestro_compromiso/database_machine_learing.svg";
import Oracle_Cloud_Infrastructure_Cloud_Operations from "../../../assets/images/oracle_nuestro_compromiso/Oracle_Cloud_Infrastructure_Cloud_Operations.svg";
import Infrastructure_Architect_Professional from "../../../assets/images/oracle_nuestro_compromiso/profesional_infraestrucutre_architect.svg";
import Infrastructure_Developer_Associate from "../../../assets/images/oracle_nuestro_compromiso/associate_infrastructure_developer.svg";
import Cloud_Infrastructure_Foundation from "../../../assets/images/oracle_nuestro_compromiso/Cloud_Infrastructure_Foundation.svg";

export const NuestrasCertificacionesOracle = () => {
  return (
    <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
      <Typography style={{ fontDisplay:"auto", fontWeight: "bold", fontSize: "24px" }}>
        Nuestras Certificaciones
      </Typography>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "30px" }}
      >
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "320px",
              maxWidth: "240px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
              gap: "1rem",
            }}
          >
            <Typography style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px" }}>
              SPECIALIST
            </Typography>
            <img alt="Specialist Oracle Cloud Platform Identity and Security Management"
              src={Platform_Identity_and_Security_Management}
              style={{
                display: "block",
                width: "100%",
                maxWidth: "220px",
              }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "320px",
              maxWidth: "240px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
              gap: "1rem",
            }}
          >
            <Typography style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px" }}>
              SPECIALIST
            </Typography>
            <img alt="Specialist Oracle Machine Learning using Autonomous Database"
              src={Database_Machine_Learning}
              style={{
                display: "block",
                width: "100%",
                maxWidth: "220px",
              }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "320px",
              maxWidth: "240px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
              gap: "1rem",
            }}
          >
            <Typography style={{ fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px" }}>
              SPECIALIST
            </Typography>
            <img alt="Specialist Oracle Cloud Database Services"
              src={Database_Services}
              style={{
                display: "block",
                width: "100%",
                maxWidth: "220px",
              }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "320px",
              maxWidth: "240px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
              gap: "1rem",
            }}
          >
            <Typography style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px" }}>
              SPECIALIST
            </Typography>
            <img alt="Specialist Oracle Cloud Platform Application Development"
              src={Platform_Application_Development}
              style={{
                display: "block",
                width: "100%",
                maxWidth: "220px",
              }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "320px",
              maxWidth: "240px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
              gap: "1rem",
            }}
          >
            <Typography style={{ color: "#FFFFFF", fontSize: "18px" }}>
              PROFESSIONAL
            </Typography>
            <img alt="Professional Oracle Cloud Infraestructure Architect"
              src={Infrastructure_Architect_Professional}
              style={{
                display: "block",
                width: "100%",
                maxWidth: "220px",
              }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "320px",
              maxWidth: "240px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
              gap: "1rem",
            }}
          >
            <Typography style={{ fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px" }}>
              ASSOCIATE
            </Typography>
            <img alt="Associate Oracle Cloud Infraestructure Architect"
              src={Infrastructure_Architect}
              style={{
                display: "block",
                width: "100%",
                maxWidth: "220px",
              }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "320px",
              maxWidth: "240px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
              gap: "1rem",
            }}
          >
            <Typography style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px" }}>
              ASSOCIATE
            </Typography>
            <img alt="Associate Oracle Cloud Infrastructure Cloud Operations"
              src={Oracle_Cloud_Infrastructure_Cloud_Operations}
              style={{
                display: "block",
                width: "100%",
                maxWidth: "220px",
              }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "320px",
              maxWidth: "240px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
              gap: "1rem",
            }}
          >
            <Typography style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px" }}>
              ASSOCIATE
            </Typography>
            <img alt="Associate Oracle Cloud Infraestructure Developer"
              src={Infrastructure_Developer_Associate}
              style={{
                display: "block",
                width: "100%",
                maxWidth: "220px",
              }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "320px",
              maxWidth: "240px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
              gap: "1rem",
            }}
          >
            <Typography style={{ fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px" }}>
              FOUNDATIONS ASSOCIATE
            </Typography>
            <img alt="Foundations Associate Oracle Cloud Infraestructure"
              src={Cloud_Infrastructure_Foundation}
              style={{
                display: "block",
                width: "100%",
                maxWidth: "220px",
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
