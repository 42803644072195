import axios from "axios"

const BASE_URL = "https://lj5aygimod.execute-api.us-east-1.amazonaws.com/prod"
const HEADERS = {
  'Content-Type': 'application/json',
  'x-api-key': '2q8YVt0Bdt10cAJtyJV0R2P2uiVrfBpu73KNEk07'
}

const config = axios.create({
	baseURL: BASE_URL,
	headers: HEADERS
})

export default config