import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import hands from '../../assets/images/servicios/hands.svg'
import check from '../../assets/images/servicios_manantial/check.svg'

import UseStyles from './UseStyless';

export default function Outsourcing_Servicios() {
    const classes = UseStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea style={{ height: "100%" }}>
                <img alt="Outsourcing" className={classes.rooter} src={hands}></img>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" style={{ textAlign: 'center', color: 'var(--rojo)' }}>
                        Outsourcing
                    </Typography>
                    <Typography variant="body2" component="p" style={{ textAlign: 'center' }}>
                        Ponemos a su disposición personas con vocación de servicios que comparten sus objetivos y soluciones de outsourcing.
                    </Typography>
                    <br></br>
                    <div style={{ display: 'flex', paddingLeft: '20px', paddingBottom: '10px' }}>
                        <img alt="check" src={check}></img>
                        <Typography style={{ paddingLeft: '10px' }}>
                            Conocimiento de la vertical de negocio en
                            la que desempeña para de este modo
                            entender el impacto sobre las necesidades
                            que se les plantea.
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', paddingLeft: '20px', paddingBottom: '10px' }}>
                        <img alt="check" src={check}></img>
                        <Typography style={{ paddingLeft: '10px' }}>
                            Mentorías, los integrantes de nuestro
                            equipo cuentan con mentorías en el
                            rubro que se desempeñan.
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', paddingLeft: '20px', paddingBottom: '10px' }}>
                        <img alt="check" src={check}></img>
                        <Typography style={{ paddingLeft: '10px' }}>Experiencia en el rol que desempeñan.</Typography>
                    </div>
                    <div style={{ display: 'flex', paddingLeft: '20px', paddingBottom: '10px' }}>
                        <img alt="check" src={check}></img>
                        <Typography style={{ paddingLeft: '10px' }}>
                            Capacitación constante con foco en las
                            necesidades de nuestros clientes y las
                            nuevas tendencias.
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}