import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import Cloud_Practitioner from "../../../assets/images/nuestro_compromiso/Cloud_Practitioner.png";
import Developer_Associate from "../../../assets/images/nuestro_compromiso/Developer_Associate.png";
import Architect_Solutions from "../../../assets/images/nuestro_compromiso/Architect_Solutions.png";
import Database_Specialty from "../../../assets/images/nuestro_compromiso/Database_Specialty.png";
import Securitys_Specialty from "../../../assets/images/nuestro_compromiso/Securitys_Specialty.png";
import Data_Analytics_Specialty from "../../../assets/images/nuestro_compromiso/Data_Analytics_Specialty.png";
import Architect_Profesional from "../../../assets/images/nuestro_compromiso/Architect_Profesional.png";
import DevOps_Engineer_Profesional from "../../../assets/images/nuestro_compromiso/DevOps_Engineer_Profesional.png";
import SysOps from "../../../assets/images/nuestro_compromiso/SysOps.png";

export const NuestrasCertificaciones = () => {
  return (
    <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
      <Typography style={{fontDisplay:"auto", fontWeight: "bold", fontSize: "24px" }}>
        Nuestras Certificaciones
      </Typography>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "30px" }}
      >
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "300px",
              maxWidth: "220px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
            }}
          >
            <Typography
              style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px", marginTop: "20px" }}
            >
              PROFESSIONAL
            </Typography>
            <img alt="Certified Solution Architect Professional"
              src={Architect_Profesional}
              style={{ marginTop: "15px", height: "150px", width: "150px" }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "300px",
              maxWidth: "220px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
            }}
          >
            <Typography
              style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px", marginTop: "20px" }}
            >
              PROFESSIONAL
            </Typography>
            <img alt="DevOps Engineer Professional"
              src={DevOps_Engineer_Profesional}
              style={{ marginTop: "15px", height: "150px", width: "150px" }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "300px",
              maxWidth: "220px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
            }}
          >
            <Typography
              style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px", marginTop: "20px" }}
            >
              SPECIALTY
            </Typography>
            <img alt="Database Specialty"
              src={Database_Specialty}
              style={{ marginTop: "15px", height: "150px", width: "150px" }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "300px",
              maxWidth: "220px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
            }}
          >
            <Typography
              style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px", marginTop: "20px" }}
            >
              SPECIALTY
            </Typography>
            <img alt="Security Specialty"
              src={Securitys_Specialty}
              style={{ marginTop: "15px", height: "150px", width: "150px" }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "300px",
              maxWidth: "220px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
            }}
          >
            <Typography
              style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px", marginTop: "20px" }}
            >
              SPECIALTY
            </Typography>
            <img alt="Data Analytics Specialty"
              src={Data_Analytics_Specialty}
              style={{ marginTop: "15px", height: "150px", width: "150px" }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "300px",
              maxWidth: "220px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
            }}
          >
            <Typography
              style={{ fontDisplay:"auto",color: "#FFFFFF", fontSize: "18px", marginTop: "20px" }}
            >
              ASSOCIATE
            </Typography>
            <img alt="Certified Solution Architect Associate"
              src={Architect_Solutions}
              style={{ marginTop: "15px", height: "150px", width: "150px" }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "300px",
              maxWidth: "220px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
            }}
          >
            <Typography
              style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px", marginTop: "20px" }}
            >
              ASSOCIATE
            </Typography>
            <img alt="Certified Developer Associate"
              src={Developer_Associate}
              style={{ marginTop: "15px", height: "150px", width: "150px" }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "300px",
              maxWidth: "220px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
            }}
          >
            <Typography
              style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px", marginTop: "20px" }}
            >
              ASSOCIATE
            </Typography>
            <img alt="SysOps Associate"
              src={SysOps}
              style={{ marginTop: "15px", height: "150px", width: "150px" }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div
            style={{
              margin: "auto",
              height: "300px",
              maxWidth: "220px",
              backgroundColor: "#232F3E",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px 0px",
            }}
          >
            <Typography
              style={{fontDisplay:"auto", color: "#FFFFFF", fontSize: "18px", marginTop: "20px" }}
            >
              FOUNDATIONAL
            </Typography>
            <img alt="Cloud Practitioner"
              src={Cloud_Practitioner}
              style={{ marginTop: "15px", height: "150px", width: "150px" }}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
