import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import "./experiencia.css";
import { VIDEOS } from "./data";
import { Typography } from "@material-ui/core";
import CardSlider from "./CardSlider";

const NextArrow = ({ onClick }) => {
  return (
    <div className="nextArrow" onClick={onClick}>
      <BsChevronRight className="color" />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow" onClick={onClick}>
      <BsChevronLeft className="color" />
    </div>
  );
};

const ImageSlider = ({ images, slidesToShow = 3 }) => {
  // 3.
  const [imageIndex, setImageIndex] = useState(0);

  // 4.
  const settings = {
    centerMode: true,
    infinite: true,
    dots: false,
    speed: 300,
    slidesToShow: slidesToShow,
    centerPadding: "0",
    swipeToSlide: true,
    focusOnSelect: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1490,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // 5.
  const templateImages = images.map((image, idx) => {
    return (
      <div
        className={idx === imageIndex ? "activeSlide" : "slide"}
        key={image.id}
      >
        <div className="slideWrapper">
          <CardSlider
            icono={image.icono}
            titulo={image.titulo}
            descripcion={image.descripcion}
            url={image.url}
          ></CardSlider>
        </div>
      </div>
    );
  });

  return <Slider {...settings}>{templateImages}</Slider>;
};

export default () => {
  return (
    <div className="App" style={{ backgroundColor: "#E6E9E9" }}>
      <Typography
        className="font-sora"
        style={{ color: "var(--rojo)", fontSize: "24px" }}
      >
        Disfruta de sus beneficios
      </Typography>
      <div className="container">
        <ImageSlider style={{ paddingBottom: "2.5rem" }} images={VIDEOS} />
      </div>
    </div>
  );
};
