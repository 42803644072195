import { Button, Container, Typography } from "@material-ui/core";
import React from "react";

export const DataBase = () => {
  return (
    <>
      <Container
        style={{
          height: "230px",
          borderRadius: "10px",
          width: "200px",
          backgroundColor: "#232F3E",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            color: "var(--rojo)",
            marginTop: "30px",
            fontWeight: "bold",
            marginBottom: "20px",
            fontDisplay:"auto",
          }}
        >
          Amazon RDS
        </Typography>
        <Typography
          style={{fontDisplay:"auto", color: "white", textAlign: "center", marginBottom: "20px" }}
        >
          Configure, use y escale una base de datos relacional en la nube
        </Typography>
        <Button
          href="./servicios/base-de-datos/RDS"
          style={{
            backgroundColor: "var(--rojo)",
            color: "white",
            width: "100px",
            height: "30px",
            fontSize: "12px",
          }}
        >
          ver más
        </Button>
      </Container>
    </>
  );
};
