import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import monitor from "../../assets/images/servicios/monitor.svg";
import check from "../../assets/images/servicios_manantial/check.svg";

import UseStyles from "./UseStyless";

export default function Monitoreo_Servicios() {
  const classes = UseStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea style={{ height: "100%" }}>
        <img alt="Monitoreo" className={classes.rooter} src={monitor}></img>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ textAlign: "center", color: "var(--rojo)" }}
          >
            Monitoreo
          </Typography>
          <Typography
            variant="body2"
            component="p"
            style={{ textAlign: "center" }}
          >
            La visibilidad de sus servicios para garantizar su disponibilidad es
            nuestro compromiso.
          </Typography>
          <br></br>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Monitoreo como servicio.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Gestión del servicio.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Implementación.
            </Typography>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
