import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import database from '../../assets/images/servicios/database.svg';
import { Button, CardActions } from '@material-ui/core';
import { Link } from "react-router-dom";
import UseStyles from './UseStyless';

export default function CardsBaseDeDatos() {
    const classes = UseStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <img alt='Base de datos' className={classes.rooter} src={database}></img >
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2" style={{fontDisplay:"auto", textAlign: 'center', color: 'var(--rojo)' }}>
                        Base de datos
                    </Typography>
                    <Typography variant="body2" component="p" style={{fontDisplay:"auto", textAlign: 'center', color: 'white' }}>
                        La disponibilidad de su información es lo que nos motiva para brindarle el mejor servicio.
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ maxHeight: '40px', alignItems: 'flex-end', justifyContent: 'center' }}>
                <Link to="/servicios" style={{textDecoration: 'none'}}>
                    <Button className={classes.button} size="small" color="primary">
                        Ver Más
                    </Button>
                </Link>
            </CardActions>
        </Card>
    );
}