import React from 'react'
import manantial_404 from '../../assets/images/404/manantial_404.svg'
import { Container, Grid, Typography } from '@material-ui/core'
import CardPageNotFound from "./CardPageNotFound"

export const PageNoFound = () => {
    return (
        <>
            <div style={{ backgroundColor: '#232F3E', height: '100%', paddingBottom: '80px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>
                    <img alt='Error 404' src={manantial_404} style={{ paddingTop: '100px', paddingBottom: '40px' }}></img>
                </div>
                    <div style={{ backgroundColor: '#323D4B', padding: '15px' }}>
                        <Typography variant="h5" component="h2" style={{ color: '#fff', textAlign: 'center' }} >
                            Por el momento nos encontramos en futuras mejoras para poder <br></br> brindarte el mejor servicio, te animamos a informarte de las <br></br>  grandes novedades de AWS y Oracle en las siguientes secciones.
                        </Typography>
                    </div>
            </div>
            <div style={{ backgroundColor: '#E6E9E9', height: '100%', paddingTop: '80px', paddingBottom: '80px' }}>
                <Container>
                    <Typography variant='h5' style={{paddingBottom:"20px"}}>
                        Explorar <label style={{ color: 'var(--rojo)' }}>AWS</label> 
                    </Typography>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                        <Grid item md={4} sm={6} xs={12}>
                            <CardPageNotFound
                                title="Cree aplicaciones de Flutter más rapido con AWS"
                                description="Una experiencia más sencilla con las aplicaciones móviles gracias a las nuevas bibliotecas de Flutter de AWS Amplify."
                                url="https://aws.amazon.com/es/getting-started/hands-on/build-flutter-app-amplify/"
                            >
                            </CardPageNotFound>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <CardPageNotFound
                                title="Amazon ElastiCache"
                                description="Obtenga un rendimiento inferior al milisegundo a escala de la nube al crear aplicaciones en tiempo real con ElastiCache for Redis."
                                url="https://aws.amazon.com/es/elasticache/redis/"
                            >
                            </CardPageNotFound>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <CardPageNotFound
                                title="Contenedores de Amazon Lightsail"
                                description="Un nuevo servicio de contenedores fácil de usar a un precio bajo y predecible."
                                url="https://aws.amazon.com/es/lightsail/"
                            >
                            </CardPageNotFound>
                        </Grid>
                    </Grid>
                    <Typography variant='h5'>
                        Explorar <label style={{ color: 'var(--rojo)', paddingTop:"50px", paddingBottom:"20px" }}>Oracle</label> 
                    </Typography>
                    <Grid container direction="row" spacing={4} justifyContent="center" alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <CardPageNotFound
                                title="Oracle Container Engine para Kubernetes"
                                description="Servicio de organización de contenedores gestionado por Oracle que puede reducir el tiempo y el coste para crear modernas aplicaciones nativas en la nube."
                                url="https://www.oracle.com/pe/cloud-native/container-engine-kubernetes/"
                            >
                            </CardPageNotFound>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <CardPageNotFound
                                title="Oracle Analytics Cloud"
                                description="Capacidades de análisis modernas, de autoservicio y con tecnología de inteligencia artificial para la preparación de datos, visualización y mucho más"
                                url="https://www.oracle.com/pe/business-analytics/analytics-cloud.html"
                            >
                            </CardPageNotFound>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <CardPageNotFound
                                title="Oracle Data Integrator Enterprise Edition"
                                description="Carga y transforma datos más rápidamente en almacenes de datos, aprovecha la potencia en lugar de depender de un servidor ETL convencional."
                                url="https://www.oracle.com/pe/integration/data-integrator-enterprise-edition/"
                            >
                            </CardPageNotFound>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}
