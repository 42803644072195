import { Button, Container, Typography } from '@material-ui/core'
import React from 'react'
import { CardEquipo } from './CardEquipo'
import Grid from '@material-ui/core/Grid';
import TeamData from "./data-equipo"
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';

export const NuestroEquipo = () => {
  return (
    <div style={{ paddingTop: '40px', paddingBottom: '60px', backgroundColor: "var(--plomo-oscuro)" }}>

      <div style={{ textAlign: "center", paddingTop: '20px', paddingBottom: '60px' }}>
        <Typography variant='h5'> <label style={{ color: 'var(--rojo)' }}>Nuestro </label> Equipo</Typography>
      </div>

      <Container>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          {
            TeamData.map(person => (
              <Grid key={uuidv4()} item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
                <CardEquipo data={person} />
              </Grid>
            ))
          }
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              href="/ayuda/trabaja-con-nosotros"
              variant="contained"
              style={{ margin: "2px", backgroundColor: 'var(--rojo)', color: "white", marginTop: "40px", padding: "10px" }}
              endIcon={<AddIcon />}
            >
              Quieres unirte
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
