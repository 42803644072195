import { Typography } from "@material-ui/core";
import React from "react";

export const CabeceraPublicacion = (props) => {
  const img = props.img;
  const title = props.titulo;
  const content = props.contenido;
  return (
    <div>
      <div>
        <img alt="img" src={img} className="img-fluid"></img>
      </div>
      <Typography
        variant="h5"
        className="font-sora"
        style={{fontDisplay:"auto", paddingTop: "25px", fontWeight: "bold" }}
      >
        {title}
      </Typography>
      <Typography
        variant="h6"
        className="font-raleway"
        style={{fontDisplay:"auto", paddingTop: "15px", paddingBottom: "15px" }}
      >
        {content}
      </Typography>
    </div>
  );
};
