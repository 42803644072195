import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import development from "../../assets/images/servicios/development.svg";
import check from "../../assets/images/servicios_manantial/check.svg";

import UseStyles from "./UseStyless";

export default function Desarrollo_Servicios() {
  const classes = UseStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea style={{ height: "100%" }}>
        <img alt="Desarrollo" className={classes.rooter} src={development}></img>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ fontDisplay:"auto", textAlign: "center", color: "var(--rojo)" }}
          >
            Desarrollo
          </Typography>
          <Typography
            variant="body2"
            component="p"
            style={{fontDisplay:"auto", textAlign: "center" }}
          >
            Afrontamos sus nuevos desafíos con soluciones innovadoras a la
            medida.
          </Typography>
          <br></br>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Desarrollo de soluciones en la nube.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Sitios web administrables.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Desarrollo e integración de API REST.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Testing de servicios y automatización.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
          >
            <img alt="check" src={check}></img>
            <Typography style={{ paddingLeft: "10px" }}>
              Capacitación de servicios Cloud.
            </Typography>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
