import React from 'react'
import './nuestrosclientes.css';
import img_delosi from '../../assets/images/clientes/delosi.png'
import img_fiduciaria from '../../assets/images/clientes/fiduciaria.png'
import img_financieraOH from '../../assets/images/clientes/financieraoh.png'
import img_grupoRokys from '../../assets/images/clientes/grupo_rokys.png'
import img_oh from '../../assets/images/clientes/oechsle.png'
import img_promart from '../../assets/images/clientes/promart.png'
import img_textiMax from '../../assets/images/clientes/textimax.png'
import img_supermercados from '../../assets/images/clientes/supermercados.png'
import img_grupoFe from '../../assets/images/clientes/grupo_fe.png'
import img_icpna from '../../assets/images/clientes/icpna.png'
import img_caja_arequipa from '../../assets/images/clientes/caja_arequipa.png'
import img_agora from '../../assets/images/clientes/agora.png'
import img_intercorp_retail from '../../assets/images/clientes/intercorp.png'
import img_izipay from '../../assets/images/clientes/izipay.png'
import img_protecta from '../../assets/images/clientes/protecta.png'



import { Container, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';


export const NuestrosClientes = () => {
  return (
    <div style={{ backgroundColor: 'var(--plomo-oscuro)', paddingTop: "20px", paddingBottom: "60px" }}>

      <div className="clients-tittle">
        <Typography variant='h5'> <label style={{ color: 'var(--rojo)' }}>Ellos confían </label> en nosotros</Typography>
      </div>

      <Container>
        <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
          <Grid container direction="row" justifyContent="center" alignItems="center">

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="DELOSI" src={img_delosi} className="clients-responsive" />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="Financiera Oh" src={img_financieraOH} className="clients-responsive" />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="PROMART" src={img_promart} className="clients-responsive" />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="Supermercados Peruanos" src={img_supermercados}className="clients-responsive" />
            </Grid>

            <Grid item alt="La Fiduciaria" src={img_fiduciaria} className="clients-responsive" />

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="OESCHLE" src={img_oh} className="clients-responsive6" />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="Textimax" src={img_textiMax} className="clients-responsive" />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="Grupo Fe" src={img_grupoFe} className="clients-responsive3" />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="ICPNA" src={img_icpna} className="clients-responsive5" />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="Caja Arequipa" src={img_caja_arequipa} className="clients-responsive4" />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="Agora" src={img_agora} className="clients-responsive5"/>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px 10px 0px" }}>
              <img alt="Intercorp Retail" src={img_intercorp_retail} className="clients-responsive2"/>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="Izipay" src={img_izipay} className="clients-responsive" />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12} style={{ textAlign: "center", padding: "30px" }}>
              <img alt="Protecta" src={img_protecta} className="clients-responsive" />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  )
}


