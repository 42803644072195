import { Container, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "50px",
  },
  heading: {
    fontSize: "17px",
    fontWeight: "bold",
  },
}));

export const InformacionCertificacionesOracle = () => {
  const classes = useStyles();
  return (
    <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
      <Typography style={{fontDisplay:"auto", fontWeight: "bold", fontSize: "24px" }}>
        Conozca a detalle sobre las certificaciones
      </Typography>
      <div className={classes.root}>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Platform Identity and Security Management - Specialist
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Este examen de especialista en administración de seguridad e
              identidad de Oracle Cloud Platform 2020 está diseñado para
              profesionales de seguridad que poseen experiencia en la
              implementación de soluciones en la nube. Esta certificación cubre
              temas como: Oracle Identity Cloud Service, Oracle CASB Cloud
              Service, arquitectura e implementación de servicios y Identity
              Security Operations Center Framework. Esta certificación valida la
              comprensión de la cartera de Oracle Cloud Security y la capacidad
              para configurar los servicios.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Database Services - Specialist
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              El candidato certificado ha demostrado el conocimiento y las
              habilidades necesarias para implementar Oracle Database Cloud
              Services en la plataforma Oracle Cloud Infrastructure. Pueden
              configurar y monitorear sistemas de base de datos Bare Metal y
              Virtual Machine (BM/VM), configurar la arquitectura de máxima
              disponibilidad (MAA) y Data Guard para Exadata Cloud Service,
              implementar Exadata Cloud at Customer, administrar la base de
              datos MySQL y usar HeatWave, y entienden los requisitos del
              servicio en la nube de la base de datos NoSQL.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Machine Learning using Autonomous Database - Specialist
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              El especialista certificado en Oracle Machine Learning con
              Autonomous Database 2021 tiene un conocimiento básico de Oracle
              Machine Learning y sus componentes está familiarizado con las
              técnicas y algoritmos de aprendizaje automático, y ha realizado
              las tareas cubiertas en el examen o las ha practicado en
              laboratorios o en un entorno. Este candidato puede crear
              proyectos, espacios de trabajo, cronogramas de trabajo, plantillas
              y cuadernos en Oracle Machine Learning.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Platform Application Development - Specialist
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Un especialista en desarrollo de aplicaciones de Oracle Cloud
              Platform 2021 ha demostrado el conocimiento y la experiencia
              necesarios para implementar soluciones en la nube. Las personas
              que obtengan esta certificación deben poder comprender y trabajar
              con Visual Builder Studio, Oracle WebLogic Server para OCI,
              Container Native Technologies (OCIR, OKE, Microservices,
              Functions), Mobile Hub y validan la comprensión de la cartera de
              desarrollo de aplicaciones y la capacidad para configurar los
              servicios.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Infrastructure Architect - Professional
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Un arquitecto profesional certificado de Oracle Cloud
              Infrastructure 2019 ha demostrado la experiencia práctica y los
              conocimientos necesarios para planificar, diseñar, implementar y
              operar soluciones en OCI. Las habilidades validadas incluyen:
              Planificar y diseñar soluciones; implementar y operar soluciones;
              Diseñar, implementar y operar bases de datos; Diseño para
              arquitectura de nube híbrida; Migrar cargas de trabajo locales a
              OCI; Diseño para seguridad y cumplimiento Se recomienda
              capacitación actualizada y experiencia de campo.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Infrastructure Architect - Associate
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Esta certificación está diseñada para personas que poseen un
              conocimiento básico sólido en la arquitectura de infraestructura
              utilizando los servicios de Oracle Cloud Infrastructure. Esta
              certificación cubre temas como: conceptos de computación en la
              nube (HA, DR, seguridad), regiones, dominios de disponibilidad,
              terminología y servicios de OCI, redes, bases de datos, base de
              datos autónoma, equilibrio de carga, FASTCONNECT, VPN,
              compartimentos, gestión de acceso e identidad y etiquetado
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Infrastructure Cloud Operations - Associate
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Un asociado de operaciones en la nube certificado de Oracle Cloud
              Infrastructure 2019 ha demostrado la experiencia práctica y el
              conocimiento necesarios para automatizar tareas en la nube,
              ajustar el rendimiento, solucionar problemas, administrar costos,
              administrar políticas de seguridad y cumplimiento, monitorear y
              alertar a OCI, implementar retención de datos y archivado, creando
              scripts de shell con la interfaz de línea de comandos (CLI) y
              diseño de agilidad a escala de la nube en OCI. Se recomienda
              capacitación actualizada y experiencia de campo.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Infrastructure Developer - Associate
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              El examen de certificación está destinado a desarrolladores que
              tienen 12 meses o más de experiencia en el desarrollo y
              mantenimiento de aplicaciones OCI. Deben tener una comprensión
              sólida de los fundamentos nativos de la nube y un conocimiento
              profundo de al menos un lenguaje de programación. Deben demostrar
              conocimientos sobre el desarrollo, la seguridad, las pruebas y el
              funcionamiento de aplicaciones en OCI y la capacidad de utilizar
              las API, la CLI y los SDK del servicio de OCI para escribir
              aplicaciones. El conocimiento práctico del arquitecto OCI, los
              casos de uso, son las mejores prácticas.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Oracle Cloud Infrastructure - Foundations Associate
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              La certificación Fundamentos de OCI está destinada a personas que
              buscan demostrar conocimiento de los servicios de nube pública
              proporcionados por OCI. La certificación es para candidatos sin
              antecedentes técnicos, como aquellos involucrados en la venta o
              adquisición de soluciones en la nube y con antecedentes técnicos
              que desean validar su nivel básico. Conocimiento sobre los
              servicios básicos de OCI. Este examen no requiere ninguna
              experiencia técnica práctica y no es un requisito previo para
              obtener otras certificaciones de OCI.
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
};
