import React from 'react'
import Cabecera from "../../components/cabecera/Cabecera"
import trabaja_con_nosotros from '../../assets/images/oportunidades/trabaja-con-nosotros.svg'
import { Button, Container, Typography } from '@material-ui/core'
import correo from '../../assets/images/social/correo.svg'
import linkedin from '../../assets/images/social/linkedin.svg';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-around'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            justifyContent: 'space-around'
        },
    }
}))
export const Oportunidades = () => {
    const classes = styles();
    return (
        <div>

            <Cabecera
                titulo={[
                    <label key="titulo_cultura" style={{ color: 'var(--rojo)' }}>Oportunidades</label>, " Manantial"
                ]}
                icono={trabaja_con_nosotros}
                descripcion="Forma parte de nuestra historia..."
            />
            <div style={{ paddingTop: '80px', backgroundColor: "var(--plomo-claro)" }}>
                <Container>
                    <Typography className="font-sora" variant="h5" component="h2" style={{
                        textAlign: 'center',
                        paddingBottom: '80px'
                    }} >
                        Te <label style={{ color: 'var(--rojo)' }}>invitamos</label> a asumir el reto de ser parte de nuestro equipo donde
                        tendrás oportunidades y muchos retos, podrás experimentar con nuevas
                        tecnologías y tener mentorias con <label style={{ color: 'var(--rojo)' }}>expertos</label>.
                    </Typography>
                </Container>
            </div>
            <div className={classes.root} style={{ marginTop: '50px', marginBottom: "50px" }}>
                <Container style={{ maxWidth: '500px' }}>
                    <Typography className="font-sora" variant="h6" style={{fontDisplay:"auto", textAlign: 'left', paddingBottom: '80px' }} >
                        Se parte de nuestro <label style={{ color: 'var(--rojo)' }}>equipo</label>, te ofrecemos un ambiente retador basado en
                        nuestra <label style={{ color: 'var(--rojo)' }}>cultura</label> donde podrás iniciar tu vida profesional o consolidarla .
                        Creemos y estamos convencidos que una empresa se hace de buenas
                        personas basado en sus <label style={{ color: 'var(--rojo)' }}>valores</label> y la <label style={{ color: 'var(--rojo)' }}>pasión</label> por lo que hacen.
                        <br /><br />
                        Acompáñanos...
                    </Typography>
                </Container>
                <Container style={{ maxWidth: '300px', marginTop: '50px' }}>

                    <Button
                        variant="contained"
                        style={{ margin: "2px", backgroundColor: 'var(--rojo)', color: "white", marginBottom: "40px", padding: "10px" }}
                        startIcon={<img alt="correo" src={correo}></img>}
                        href="mailto:builders@manantial.pe"
                    >
                        builders@manantial.pe
                    </Button>
                    <Button
                        variant="contained"
                        style={{ margin: "2px", backgroundColor: 'var(--azul)', color: "white", padding: "10px" }}
                        startIcon={<img alt="linkedin" src={linkedin}></img>}
                        target="_blank"
                        href="https://www.linkedin.com/company/manantial-per%C3%BA/mycompany/"
                    >
                        Manantial Tecnológico
                    </Button>
                </Container>
            </div>
        </div >
    )
}

