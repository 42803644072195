import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        maxWidth: "400px",
        minHeight: "550px",
        margin: 'auto',
        backgroundColor: '#232F3E',
        color: 'white !important'
    },
    rooter: {
        width: '100%',
        height: '80px',
        padding: '20px 0px 0px',
        color: 'white !important'
    }
}));