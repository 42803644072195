import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        maxWidth: 318,
        minHeight: 269,
        margin: 'auto',
        backgroundColor: '#232F3E',
        color: 'white !important'
    },
    rooter: {
        width: '100%',
        height: '60px',
        padding: '20px 0px 0px',
        color: 'white !important'
    },
    button: {
        marginTop: '8px',
        fontWeight: '100',
        color: 'white !important',
        backgroundColor: 'var(--rojo) !important',
        width: 'auto !important',
        padding: '4px 8px',
        borderRadius: '2px',
    }
}));