import React from 'react'
import Cabecera from '../../components/cabecera/Cabecera'
import Certficacion from '../../assets/images/nuestro_compromiso/Certificacion.svg'
import { NuestrasCertificaciones } from '../../components/aws/nuestro_compromiso/NuestrasCertificaciones'
import { InformacionCertificaciones } from '../../components/aws/nuestro_compromiso/InformacionCertificaciones'

export const NuestroCompromiso = () => {
    return (
        <>
            <Cabecera
                titulo={[
                    "Nuestro ", <label key="titulo_nuestro_compromiso" style={{ color: 'var(--rojo)' }}>compromiso</label>, " con ", <label key="titulo_cultura" style={{ color: 'var(--rojo)' }}>AWS</label>
                ]}
                icono={Certficacion}
                descripcion="Compartimos el mismo objetivo y nos esforzamos para conseguirlo"
            />
            <NuestrasCertificaciones/>
            <InformacionCertificaciones/>
        </>
    )
}
