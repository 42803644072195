import React from 'react'
import CardsBaseDeDatos from './CardsBaseDeDatos'
import CardsCloud from './CardsCloud'
import CardsMonitoreo from './CardsMonitoreo'
import CardsOutsourcing from './CardsOutsourcing'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Container, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-around'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            justifyContent: 'space-around'
        },
    },
}));

export const NuestrosServicios = () => {

    return (
        <div className='services-container' style={{ paddingBottom: '64px', backgroundColor: 'var(--plomo-claro) !important', backgroundPosition: 'top'}}>
            <div style={{ textAlign: 'center', paddingTop: '40px', paddingBottom: '40px' }}>
                <Typography variant='h5'>Conoce <label style={{ color: 'var(--rojo)' }}>nuestros servicios</label> </Typography>
            </div>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                        <CardsBaseDeDatos></CardsBaseDeDatos>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <CardsCloud></CardsCloud>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <CardsMonitoreo></CardsMonitoreo>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <CardsOutsourcing></CardsOutsourcing>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
