import { Typography, Container } from '@material-ui/core'
import React from 'react'
import servicios_manantial from '../../assets/images/servicios_manantial/servicios_manantial.svg'
import Cloud_Servicios from './Cloud_Servicios'
import DataBase from './DataBase'
import Monitoreo_Servicios from './Monitoreo_Servicios'
import Outsourcing_Servicios from './Outsourcing_Servicios'
import Desarrollo_Servicios from './Desarrollo_Servicios'
import Gestionado_Servicios from './Gestionado_Servicios'
import Cabecera from "../cabecera/Cabecera"
import Grid from '@material-ui/core/Grid';


export default function ServiciosManantial (props) {

    return (
        <>
            <Cabecera
                titulo={[
                    <label key="titulo_servicios" style={{ color: 'var(--rojo)' }}>Servicios</label>, " Manantial"
                ]}
                icono={ servicios_manantial }
                descripcion="Amamos la tecnología y las soluciones!!!"
            ></Cabecera>

            <div style={{paddingTop: '90px', paddingBottom: '80px'}}>
                <Container>
                    <Typography variant="h5" component="h2" style={{fontDisplay:"auto", textAlign: 'center', paddingBottom: '80px'}} >Aquí en <label style={{ color: 'var(--rojo)' }}>Manantial</label> contamos con los siguientes servicios, los cuales<br></br>están diseñados para proporcionar la mejor experiencia y satisfacción a <br></br> todos <label style={{ color: 'var(--rojo)' }}>nuestros clientes</label></Typography> 
                </Container>
                
                <div>
                    
                    <Container>

                        <Grid container direction="row" spacing={4}>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                               <Cloud_Servicios></Cloud_Servicios>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                               <DataBase></DataBase>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                               <Gestionado_Servicios></Gestionado_Servicios>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                               <Desarrollo_Servicios></Desarrollo_Servicios>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                               <Monitoreo_Servicios></Monitoreo_Servicios>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                               <Outsourcing_Servicios></Outsourcing_Servicios>
                            </Grid>

                        </Grid>

                    </Container>

                </div>
            </div>
        </>
    )
}
