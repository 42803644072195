import React from "react";
import Certficacion from "../../../assets/images/nuestro_compromiso/Certificacion.svg";
import Cabecera from "../../cabecera/Cabecera";
import { InformacionCertificacionesOracle } from "./InformacionCertificacionesOracle";
import { NuestrasCertificacionesOracle } from "./NuestrasCertificacionesOracle";

export const NuestroCompromisoOracle = () => {
  return (
    <>
      <Cabecera
        titulo={[
          "Nuestro ",
          <label
            key="titulo_nuestro_compromiso"
            style={{ color: "var(--rojo)" }}
          >
            compromiso
          </label>,
          " con ",
          <label key="titulo_cultura" style={{ color: "var(--rojo)" }}>
            Oracle
          </label>,
        ]}
        icono={Certficacion}
        descripcion="Compartimos el mismo objetivo y nos esforzamos para conseguirlo"
      />
      <NuestrasCertificacionesOracle />
      <InformacionCertificacionesOracle />
    </>
  );
};
