import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Typography,
    Button,
    CardMedia,
  } from "@material-ui/core";
  import CasoConect from "../../../assets/images/casos_de_exito/portada-delosi-connect.jpg";
  import React from "react";
  import {Link} from "react-router-dom";
  
  export const CardCasosConnect = (props) => {
    return (
      <Card
        style={{
          backgroundColor: "#232F3E",
          margin: "auto",
          minWidth: "200px",
          maxWidth: "300px",
        }}
      >
        <CardActionArea>
          <CardMedia
            style={{ height: "180px" }}
            image={CasoConect}
            title="Central-Telefonica-Connect"
          />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{fontDisplay:"auto", color: "white" }}
            >
              Descubre como uno de los operadores de franquicias mas grandes del Perú moderniza las tareas de su contact center.
              <br />
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions
          style={{
            maxHeight: "40px",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Link
            to="/aws/casos-exito/central-telefonica-connect"
            style={{
              backgroundColor: "var(--rojo)",
              color: "white",
              padding: "4px 8px",
              borderRadius: "2px",
            }}
            size="small"
            color="primary"
          >
            <Button
            style={{
              backgroundColor: "var(--rojo)",
              color: "white",
              padding: "4px 8px",
              borderRadius: "2px",
            }}
            >
              Ver Más
            </Button>
          </Link>
          
        </CardActions>
      </Card>
    );
  };
  